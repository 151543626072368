import './Login.css'
import React, {useState} from 'react'
import axios from 'axios'

function Login () {

    const [userLogin, setUserLogin] = useState('')
    const [userPassword, setUserPassword] = useState('')

    function handleLogin (event) {
        setUserLogin(event.target.value)
        
    }

    function handlePassword (event) {
        setUserPassword(event.target.value)
    }

    function connect() {
        axios.post('/api/login/check', {
            login: userLogin,
            password:userPassword,
        }).then(reponse => {
            
            if(reponse.data.login === "OK")
            {
                document.location.href = "/admin"
            }
            else
            {
                alert("Mauvais Login/Motdepasse")
            }
            
        })
    }

    return (
        <div id="Login">
            <div className="login-cadre">
                <h3> Espace Mairie </h3> 
                <input type="text" placeholder="Utilisateur" className="login-cadre-user" onChange={handleLogin}></input>
                <input type="password" placeholder="Motdepasse" className="login-cadre-pwd" onChange={handlePassword}></input>
                <button onClick={connect} className="login-cadre-bouton">  Connexion </button>
            </div>
        </div>
    )
}

export default Login