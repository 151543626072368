import './UserLigne.css'
import logodelete from '../../../Image/del.png'
import logoedit from '../../../Image/edit.png'
import logocancel from '../../../Image/quit.png'
import logovalid from '../../../Image/valid.png'
import axios from 'axios'
import React, {useState} from 'react'

function UserLigne(props) {

    let user = props.data

    const [userPassword, setUserPassword] = useState(user.password)
    const [userNom, setUserNom] = useState(user.nom)
    const [userPrenom, setUserPrenom] = useState(user.prenom)
    const [userRole, setUserRole] = useState(user.role)
    const [userEmail, setUserEmail] = useState(user.email)

    function handlePassword (event)
    {
        setUserPassword(event.target.value)
    }

    function handleNom (event)
    {
        setUserNom(event.target.value)
    }

    function handlePrenom(event)
    {
        setUserPrenom(event.target.value)
    }
    
    function handleRole(event)
    {
        setUserRole(event.target.value)
    }

    function handleEmail(event)
    {
        setUserEmail(event.target.value)
    }

    function displayLigneEdit(event)
    {
        let divid = event.target.parentNode.id
        
        let regexp = /[0-9].*/

        let userid = divid.match(regexp);

        let divshow = document.getElementById('users-data-list-ligne-show'+userid)
        let divedit = document.getElementById('users-data-list-ligne-edit'+userid)

        divshow.style.display = "none"
        divedit.style.display = "grid"
    }

    function displayLigneShow(event)
    {
        let divid = event.target.parentNode.id
        
        let regexp = /[0-9].*/

        let userid = divid.match(regexp);

        let divshow = document.getElementById('users-data-list-ligne-show'+userid)
        let divedit = document.getElementById('users-data-list-ligne-edit'+userid)

        divshow.style.display = "grid"
        divedit.style.display = "none"
    }

    function deleteUser(event)
    {
        let idtemp = event.target.parentNode.id

        let regexp = /[0-9].*/

        let id = idtemp.match(regexp)

        axios.post('/api/users/delete', {
            id : id[0]
        }).then(reponse => {
            console.log(reponse.data)
        })
    }

    function sendData(event) 
    {
        let idtemp = event.target.parentNode.id

        let regexp = /[0-9].*/

        let id = idtemp.match(regexp)

        axios.post('/api/users/update', {
            iduser: id[0],
            nom: userNom,
            prenom: userPrenom,
            pwd: userPassword,
            role: userRole,
            email:userEmail,
        }).then(reponse => {
            setUserPrenom(reponse.data[0].prenom)
        })
    }

    return (
        <div id="UserLigne">
            <div id={'users-data-list-ligne-show'+user._id} className="users-data-list-ligne-show">
                <p> {user.login} </p>
                <p style={{ fontSize: "6px"}}> {userPassword} </p>
                <p> {userNom} </p>
                <p> {userPrenom}</p>
                <p> {userEmail} </p>
                <p> {userRole} </p>
                <div id={'users-data-list-ligne-show-icon'+user._id} className="users-data-list-ligne-show-icon">
                    <img className="users-data-list-ligne-show-image" onClick={displayLigneEdit} alt="" src={logoedit}></img>
                    <img className="users-data-list-ligne-show-image" onClick={deleteUser} alt="" src={logodelete}></img>
                </div>
            </div>
            <div id={'users-data-list-ligne-edit'+user._id} className="users-data-list-ligne-edit">
                <p> {user.login} </p>
                <input value={userPassword} type="password" onChange={handlePassword} />
                <input value={userNom} type="text" onChange={handleNom}/>
                <input value={userPrenom} type="text" onChange={handlePrenom}/>
                <input value={userEmail} type="text" onChange={handleEmail}></input>
                <select value={userRole} onChange={handleRole}> 
                    <option value="Admin"> Admin </option>
                    <option value="Employe"> Employe </option>
                </select>
                <div id={'users-data-list-ligne-edit-icon'+user._id} className="users-data-list-ligne-edit-icon">
                    <img className="users-data-list-ligne-edit-image" onClick={sendData} alt="" src={logovalid}></img>
                    <img className="users-data-list-ligne-edit-image" onClick={displayLigneShow} alt="" src={logocancel}></img>
                </div>
            </div> 
        </div>      
    )
    
}

export default UserLigne