import './AssocAdd.css'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';

function AssocAdd ()
{
    const [image, setImage] = useState()
    const [nomImage, setnomImage] = useState()
    const [titreassoc, settitreassoc] = useState()
    const [textassoc, settextassoc] = useState()
    const [progressionImage, setProgressionImage] = useState()
 
    //Gestion Image
    const handleImage = (event) => {
       
        setImage(event.target.files[0])
    }

    const sendImageAssoc = (event) => {

        let data = new FormData()
        event.preventDefault()
        
        data.append('image', image)
            
        axios.post('/api/assoc/addimage', data, {
            headers:{
                'Content-Type':'multipart/form-data'
            },
            onUploadProgress: async (progressEvent) => {
                setProgressionImage(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        }).then(reponse => {
            setnomImage(reponse.data)
        })
           
    }

    const handleTitreAssoc = (event) => {
        settitreassoc(event.target.value)
    }

    const handleTextAssoc = (value, editor) => {
        settextassoc(editor.getContent())
    }

    const addAssoc = () => {
        
        axios.post('/api/assoc/create', {
            nomimg : nomImage,
            text: textassoc,
            titre: titreassoc,
        }).then(reponse => {
            if(reponse.data === "ok")
            {
                alert("L'association a été ajouté")
                document.location.href = "/admin/assoc"
            }
        })  
        
    }

    return (
        <div className="AssocAdd">
            <div className="assocadd-titre">
                <h1> Ajouter une assocalité </h1> 
            </div>
            <div className="assocadd-data">
                <div className="assocadd-data-gauche">
                    <div className="assocadd-data-gauche-entete">
                        <h1> Menu </h1>
                    </div>
                    <div className="assocadd-data-gauche-menu">
                        <Link className="assocadd-data-gauche-menu-lien" to="/admin/assoc"> Revenir à la page Association </Link>
                    </div>
                </div>
                <div className="assocadd-data-droite">
                    <div className="assocadd-form-titre">
                        <h1> Formulaire d'ajout </h1>     
                    </div> 
                    <div className="assocadd-form-data">
                        <p> Titre Association  </p>
                        <input type="text" onChange={handleTitreAssoc}></input>
                        <p> Texte Association </p>
                        <Editor
                            onEditorChange={handleTextAssoc}
                            tinymceScriptSrc="/tinymce/tinymce.min.js"
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                        <div className="assocadd-form-data-image">
                            <h1> Charger une Image </h1>
                            <input type="file" placeholder="Selectionner une Image" onChange={handleImage}></input>
                            <button onClick={sendImageAssoc}> Charger l'Image </button>
                        </div>
                        {progressionImage &&
                            <p className="assocadd-loading"> Téléchargement en cours {progressionImage} % </p>
                        }
                        {nomImage &&
                            <div className="assocadd-photosres">
                                <img alt="photores" src={"/images/"+nomImage}></img>
                            </div>
                        }
                    </div>
                    {textassoc &&
                        <div className="assocadd-form-submit">
                            <button onClick={addAssoc}> Ajouter Association </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AssocAdd