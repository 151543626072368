import './Demarches.css'
import axios from 'axios'
import {useEffect, useState} from 'react'
import pacs from '../../Image/pacs.jpg'
import etat from '../../Image/Etat-Civil.jpg'
import cni from '../../Image/cni.png'
import passport from '../../Image/passport.png'
import vote from '../../Image/vote.png'
import courrier from '../../Image/courrier.jpg'
import internet from '../../Image/internet.jpg'
import mairieF from '../../Image/mairie-front.jpg'
import house from '../../Image/house.png'
import contract from '../../Image/contract.png'
import Diaporama from '../Diaporama/Diaporama'
import Popup from '../Popup/Popup'

function Demarches () 
{
    const [tabdiapos,setTabDiapos] = useState([])
    const [tabsalles, setTabSalles] = useState([])
    const [nom, setNom] = useState()
    const [prenom, setPrenom] = useState()
    const [tel, setTel] = useState()
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [erreursTab, seterreursTab] = useState([])

    //Popup 
    const [dataPopup, setdataPopup] = useState([])
    const [dataPopup2, setdataPopup2] = useState([])
    const [dataPopup3, setdataPopup3] = useState([])
    const [dataPopup4, setdataPopup4] = useState([])
    const [dataPopup5, setdataPopup5] = useState([])
    const [dataPopup6, setdataPopup6] = useState([])

    const [isPopup1, setisPopup1] = useState(false)
    const [isPopup2, setisPopup2] = useState(false)
    const [isPopup3, setisPopup3] = useState(false)
    const [isPopup4, setisPopup4] = useState(false)
    const [isPopup5, setisPopup5] = useState(false)
    const [isPopup6, setisPopup6] = useState(false)

    const showPopup = () => {
        setisPopup1(true)
    }

    const showPopup2 = () => {
        setisPopup2(true)
    }

    const showPopup3 = () => {
        setisPopup3(true)
    }

    const showPopup4 = () => {
        setisPopup4(true)
    }

    const showPopup5 = () => {
        setisPopup5(true)
    }

    const showPopup6 = () => {
        setisPopup6(true)
    }


    useEffect(() => {
        axios.get('/api/photos/listdemarches').then(reponse => {
            setTabDiapos(reponse.data)
        })

        axios.get('/api/photos/listsalles').then(reponse => {
            setTabSalles(reponse.data)
        })

        //PACS
        axios.post('/api/textes/listbycode', {
            code: "TXT2205-204"
        }).then(reponse => {
            setdataPopup(reponse.data)
        })

        //CARTE D'IDENTITE
        axios.post('/api/textes/listbycode', {
            code : "TXT2205-205"
        }).then(reponse => {
            setdataPopup2(reponse.data)
        })

        //PASSEPORT
        axios.post('/api/textes/listbycode', {
            code : "TXT2205-206"
        }).then(reponse => {
            setdataPopup3(reponse.data)
        })

        //PIECE D'IDENTITE
        axios.post('/api/textes/listbycode', {
            code : "TXT2205-207"
        }).then(reponse => {
            setdataPopup4(reponse.data)
        })

        //JUSTIFICATIF DE DOMICILE
        axios.post('/api/textes/listbycode', {
            code : "TXT2205-208"
        }).then(reponse => {
            setdataPopup5(reponse.data)
        })

        //UNE JUSTIFICATION EN QUALITÉ DE CONTRIBUABLE
        axios.post('/api/textes/listbycode', {
            code : "TXT2205-209"
        }).then(reponse => {
            setdataPopup6(reponse.data)
        })

    },[])

    const handleFormNom = (event) => {
        setNom(event.target.value)
    }

    const handleFormPrenom = (event) => {
        setPrenom(event.target.value)
    }

    const handleFormTel = (event) => {
        setTel(event.target.value)
    }

    const handleFormEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleFormMessage = (event) => {
        setMessage(event.target.value)
    }

    const sendMessage = async () => {
        let reponse = await axios.post('/api/messages/create', {
            nom:nom,
            prenom:prenom,
            tel:tel,
            email:email,
            message:message,
        })
    
        if(reponse.data.errors)
        {
            seterreursTab(reponse.data.errors)   
        }
        else if(reponse.data === "ok")
        {
            alert("Un Email nous a été envoyé Merci")
            document.location.reload(true)
        }
    }

    const goToFormCF = (event) => {

        axios.get('/api/docs/getformcf', {
            responseType:"blob",
        }).then(reponse => {
            
            const file = new Blob([reponse.data], {type:'application/pdf'})

            const fileurl = URL.createObjectURL(file)

            window.open(fileurl)
        })
    }

    const goToFormCE = (event) => {

        axios.get('/api/docs/getformce', {
            responseType:"blob",
        }).then(reponse => {
            
            const file = new Blob([reponse.data], {type:'application/pdf'})

            const fileurl = URL.createObjectURL(file)

            window.open(fileurl)
        })
    }

    const goToFormEL = (event) => {

        axios.get('/api/docs/getformel', {
            responseType:"blob",
        }).then(reponse => {
            
            const file = new Blob([reponse.data], {type:'application/pdf'})

            const fileurl = URL.createObjectURL(file)

            window.open(fileurl)
        })
    }

    const goToRegister = (event) => {

        document.location.href = "https://www.service-public.fr/particuliers/vosdroits/R16396"
    }

    return (
        <div className="Demarches">
            {isPopup1 &&
                <Popup isclose={isPopup1 => setisPopup1(isPopup1)} data={dataPopup}></Popup> 
            }
            {isPopup2 &&
                <Popup isclose={isPopup2 => setisPopup2(isPopup2)} data={dataPopup2}></Popup> 
            }
            {isPopup3 && 
                <Popup isclose={isPopup3 => setisPopup3(isPopup3)} data={dataPopup3}></Popup>
            }
            {isPopup4 &&
                <Popup isclose={isPopup4 => setisPopup4(isPopup4)} data={dataPopup4}></Popup> 
            }
            {isPopup5 &&
                <Popup isclose={isPopup5 => setisPopup5(isPopup5)} data={dataPopup5}></Popup> 
            }
            {isPopup6 && 
                <Popup isclose={isPopup6 => setisPopup6(isPopup6)} data={dataPopup6}></Popup>
            }
            <Diaporama data={tabdiapos} classdiapos="diapos" classslider="slider"></Diaporama>
            <div className="demarche-titre">
                <h1>Vos Démarches</h1>
            </div>
            <div className="demarche-classeur">
                <h1>DÉMARCHES ADMINISTRATIVES</h1>
            </div>
            <div className="demarche-carte">
                    <div className="demarche-carteG">
                        <h1>PACS</h1>
                        <img src={pacs} alt='pacs'></img>
                        <button onClick={showPopup}>En savoir plus</button>
                    </div>
                    <div className="demarche-carteD">
                        <h1>ETAT CIVIL</h1>
                        <img src={etat} alt='etat'></img>
                    </div>
            </div>
            <div className="demarche-partie7">
                <div className="demarche-partie7-gauche">
                    <h1>CARTE D'IDENTITE</h1>
                    <img src={cni} alt='cni'></img>
                    <button onClick={showPopup2}>En savoirs plus</button>
                </div>
                <div className="demarche-partie7-droite">
                    <h1>PASSEPORT</h1>
                    <img src={passport} alt='passport'></img>
                    <button onClick={showPopup3}>En savoir plus</button>
                </div>
            </div>
            <div className="demarche-election">
                <h1>LES ELECTIONS</h1>
            </div>

            <div className="demarche-inscription">
                <h1>COMMENT S'INSCRIRE ?</h1>
            </div>
            <div className="demarche-partie9">
                <div className="demarche-partie9-gauche">
                    <h1>Inscription Listes Eléctorales</h1>
                    <img src={vote} alt='vote'></img>
                </div>
                <div className="demarche-partie9-milieu">
                    <div className="demarche-partie9-milieu-cadre">
                        <img src={internet} alt='internet'></img>
                        <h1>Par Internet</h1>
                        <p>En utilisant le téléservice proposer par www.service-public.fr</p>
                        <button onClick={goToRegister}>Voir le site</button>
                    </div>
                    <div className="demarche-partie9-milieu-cadre">
                        <img src={mairieF} alt='mairieF'></img>
                        <h1>En Mairie</h1>
                        <p>En vous rendant en Mairie avec les pièces exigées</p>
                        <button>Voir les Coordonnées </button>
                    </div>
                    <div className="demarche-partie9-milieu-cadre">
                        <img src={courrier} alt='courrier'></img>
                        <h1>Par Courrier</h1>
                        <p>En expédiant le formulaire d'inscriptions et les pièces demandées à cette adresse</p>
                        <button>voir les coordonnées</button>
                    </div>           
                </div>
                <div className="demarche-partie9-droite">
                    <h1>Formulaires d'Inscription</h1>
                    <p>Ressortissants Français</p>
                    <button onClick={goToFormCF}>telecharger</button>
                    <p>Citoyens Etrangers</p>
                    <button onClick={goToFormCE}>telecharger</button>
                    <p>Inscription sur les listes Européennes</p>
                    <button onClick={goToFormEL}>telecharger</button>
                </div>
            </div>
            <div className="demarche-piece">
                <h1>LES PIÈCES À FOURNIR</h1>
            </div>
            <div className="demarche-partie10">
                <div className="demarche-partie10-cadre">
                    <h1>UNE PIÈCE D'IDENTITÉ RÉCENTE</h1>
                    <img src={cni} alt='cni'></img>
                    <button onClick={showPopup4}>En savoir plus</button>
                </div>
                <div className="demarche-partie10-cadre">
                    <h1>UN JUSTIFICATIF DE DOMICILE</h1>
                    <img src={house} alt='house'></img>
                    <button onClick={showPopup5}>En savoir plus</button>
                </div>
                <div className="demarche-partie10-cadre">
                    <h1>UNE JUSTIFICATION EN QUALITÉ DE CONTRIBUABLE</h1>
                    <img src={contract} alt='contract'></img>
                    <button onClick={showPopup6}>En savoir plus</button>
                </div>
            </div>
            <Diaporama data={tabsalles} classdiapos="diapos" classslider="slider"></Diaporama>
            <div className="demarche-louer">
                <h1>LOUER LA SALLE</h1>
            </div>
            <div className="demarche-form-reserv">
                <div className="demarche-form-reserv-titre">
                    <h1> Formulaire de Réservation </h1>
                </div>
                <div className="demarche-form-reserv-data">
                    <p> Nom </p>
                    <input type="text" onChange={handleFormNom} placeholder="Nom"></input>
                    {erreursTab.map(erreur => 
                        erreur.param === "nom" ?
                        (
                            <div className="erreur-ligne-formcontact">
                                {erreur.msg}
                            </div>
                        ):null
                    )}
                    <p> Prenom </p>
                    <input type="text" onChange={handleFormPrenom} placeholder="Prenom"></input>
                    {erreursTab.map(erreur => 
                        erreur.param === "prenom" ?
                        (
                            <div className="erreur-ligne-formcontact">
                                {erreur.msg}
                            </div>
                        ):null
                    )}
                    <p> Email </p>
                    <input type="text" onChange={handleFormEmail} placeholder="Email"></input> 
                    {erreursTab.map(erreur => 
                        erreur.param === "email" ?
                        (
                            <div className="erreur-ligne-formcontact">
                                {erreur.msg}
                            </div>
                        ):null
                    )}
                    <p> Tel </p>
                    <input type="text" onChange={handleFormTel} placeholder="Tel"></input> 
                    {erreursTab.map(erreur => 
                        erreur.param === "tel" ?
                        (
                            <div className="erreur-ligne-formcontact">
                                {erreur.msg}
                            </div>
                        ):null
                    )}
                    <p> Message</p>
                    {erreursTab.map(erreur => 
                        erreur.param === "message" ?
                        (
                            <div className="erreur-ligne-formcontact">
                                {erreur.msg}
                            </div>
                        ):null
                    )}
                    <textarea onChange={handleFormMessage} placeholder="Vous pouvez écrire ici"></textarea>
                </div>
                <div className="demarche-form-reserv-submit">
                    <button onClick={sendMessage}> Envoyer la réservation </button>
                </div>
            </div>
        </div>
    )
}

export default Demarches