import './PhotosLigne.css'
import {useState, useEffect} from 'react'
import axios from 'axios'

function PhotosLigne (props)
{
    const [photonom, setphotonom] = useState()
    const [imageSelect, setimageSelect] = useState()
    const [progression, setProgression] = useState()
    
    useEffect(() => {
        setphotonom(props.data.nom)
    },[props.data])

    const handleDiapo = (event) => {
        setimageSelect(event.target.files)
    }

    const editDiapo = async (event) => {
        
        let id = event.target.parentNode.getAttribute('value')
        
        let data = new FormData()
        event.preventDefault()

        data.append('image', imageSelect[0])   
        data.append('id', id)

        if(props.data.categorie === "Diaporama")
        {
            axios.post('/api/photos/editaccueil', data, {
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: async (progressEvent) => {
                    setProgression(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(reponse => {
                if(reponse.data)
                {
                    alert("La photo a été modifiée")
                    document.location.reload()
                }
            })
        }
        else if(props.data.categorie === "Commune")
        {
            axios.post('/api/photos/editcommune', data, {
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: async (progressEvent) => {
                    setProgression(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(reponse => {
                if(reponse.data)
                {
                    alert("La photo a été modifiée")
                    document.location.reload()
                }
            })
        }
        else if(props.data.categorie === "Demarches")
        {
            axios.post('/api/photos/editdemarches', data, {
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: async (progressEvent) => {
                    setProgression(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(reponse => {
                if(reponse.data)
                {
                    alert("La photo a été modifiée")
                    document.location.reload()
                }
            })
        }
    }

    return (
        <div value={props.data._id} key={props.data._id} className="PhotosLigne">
            <img src={'../../images/'+photonom} alt="gesaccueil-photolignes"></img>
            <input type="file" placeholder="Selectionner une Image" onChange={handleDiapo}></input>
            <button onClick={editDiapo}> Modifier </button>
            {progression &&
                <p className="photosadd-loading"> Téléchargement en cours {progression} % </p>
            }
        </div>
    )   
    
}

export default PhotosLigne