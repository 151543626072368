import './UsersAdd.css'
import {Link} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import axios from 'axios'

function UsersAdd () {

    const [userLogin, setUserLogin] = useState("")    
    const [userPassword, setUserPassword] = useState("")
    const [userNom, setUserNom] = useState("")
    const [userPrenom, setUserPrenom] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userRole, setUserRole] = useState("")
    
    useEffect(() => {
        if(userPrenom !== null && userNom != null)
        {
            handleUserLogin()
        }
    })

    function handleUserLogin() {
        let login = (userPrenom+(".")+userNom).toLowerCase()
        setUserLogin(login)
    }

    function handleUserPassword(event) {
        setUserPassword(event.target.value)
    }

    function handleUserNom(event) {
        setUserNom(event.target.value)
    }

    function handleUserPrenom(event) {
        setUserPrenom(event.target.value)
    }

    function handleUserEmail(event) {
        setUserEmail(event.target.value)
    }

    function handleUserRole(event) {
        setUserRole(event.target.value)
    }

    function sendData(event) {
        axios.post('/api/users/create', {
            login:userLogin,
            password: userPassword,
            nom: userNom,
            prenom: userPrenom,
            email: userEmail,
            role:userRole,
        })
    }

    let divLogin = ""

    if(userPrenom && userNom)
    {
        divLogin = (
            <div className="usersadd-data-droite-form-items">
                <p> Login </p>
                <p> {userLogin} </p>
            </div>
        )
    }
      
    return (
        <div id="Usersadd">
            <div className="usersadd-titre">
                <h1> Création Utilisateur </h1>
            </div>
            <div className="usersadd-data">
                <div className="usersadd-data-gauche">
                    <div className="usersadd-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="usersadd-data-gauche-menu">
                        <Link to="/admin/users" className="usersadd-data-gauche-menu-lien"> Revenir page Utilisateurs </Link> 
                    </div>
                </div>
                <div className="usersadd-data-droite">
                    <div className="usersadd-data-droite-entete">
                        <h1> Formulaire de Création </h1> 
                    </div>
                    <div className="usersadd-data-droite-form">
                        <div className="usersadd-data-droite-form-items">
                            <p> Prenom </p>
                            <input placeholder="Prenom" onChange={handleUserPrenom}></input>
                        </div>
                        <div className="usersadd-data-droite-form-items">
                            <p> Nom </p>
                            <input placeholder="Nom" onChange={handleUserNom}></input>
                        </div>
                        {divLogin}
                        <div className="usersadd-data-droite-form-items">
                            <p> Password </p>
                            <input placeholer="Motdepasse" type="password" onChange={handleUserPassword}></input>
                        </div>
                        <div className="usersadd-data-droite-form-items">
                            <p> Adresse Email </p>
                            <input placeholer="Email" onChange={handleUserEmail}></input>
                        </div>
                        <div className="usersadd-data-droite-form-items">
                            <p> Role </p>
                            <select onChange={handleUserRole}>
                                <option> Choisisser un Role </option>
                                <option> Employe </option> 
                                <option> Admin </option>
                            </select>
                        </div>
                        <div className="usersadd-data-droite-form-items">
                            <button className="usersadd-data-droite-form-bouton" onClick={sendData}> Créer </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsersAdd