import './Photos.css'
import { Link } from 'react-router-dom'
import {useState, useEffect} from 'react'
import axios from 'axios'
import PhotosLigne from './PhotosLigne'

function Photos ()
{
    const [diaporamaAccueil, setDiaporamaAccueil] = useState([])
    const [diaporamaCommune, setDiaporamaCommune] = useState([])
    const [diaporamaDemarches, setDiaporamaDemarches] = useState([])

    useEffect(() => {
        axios.get('/api/photos/listaccueil').then(reponse => {
            setDiaporamaAccueil(reponse.data)
        })

        axios.get('/api/photos/listcommune').then(reponse => {
            setDiaporamaCommune(reponse.data)
        })

        axios.get('/api/photos/listdemarches').then(reponse => {
            setDiaporamaDemarches(reponse.data)
        })
    },[])


    return (
        <div className="Photos">
            <div className="photos-titre">
                    <h1> Gestion des Photos  </h1>
             </div>
            <div className="photos-data">
                <div className="photos-data-gauche">
                    <div className="photos-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="photos-data-gauche-menu">
                        <Link to="/admin" className="photos-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/photosadd" className="photos-data-gauche-menu-lien"> Ajouter des Photos </Link> 
                    </div>
                </div>
                <div className="photos-data-droite">
                    <div className="photos-data-droite-titre">
                        <h1> Formulaire de gestions des Photos </h1>
                    </div>
                    <div className="photos-data-droite-photos">
                        <div className="photos-diaporamaaccueil-titre">
                            <h1> Diaporama Accueil </h1> 
                        </div>
                        <div className="photos-diaporamaccueil-data">
                            {diaporamaAccueil.map(diapo => 
                                <PhotosLigne data={diapo}></PhotosLigne>
                            )}
                        </div>
                        <div className="photos-diaporamaaccueil-titre">
                            <h1> Diaporama Commune </h1> 
                        </div>
                        <div className="photos-diaporamaccueil-data">
                            {diaporamaCommune.map(diapo => 
                                <PhotosLigne data={diapo}></PhotosLigne>
                            )}
                        </div>
                        <div className="photos-diaporamaaccueil-titre">
                            <h1> Diaporama Démarches </h1> 
                        </div>
                        <div className="photos-diaporamaccueil-data">
                            {diaporamaDemarches.map(diapo => 
                                <PhotosLigne data={diapo}></PhotosLigne>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default Photos