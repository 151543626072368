import './Admin.css'
import { Link } from 'react-router-dom'
import photo from '../../Image/appareil-photo.png'
import actus from '../../Image/actusadmin.png'
import events from '../../Image/event.png'
import docs from '../../Image/docs.png'
import textes from '../../Image/textes.png'
import horaires from '../../Image/lhorloge.png'

function Admin () {
    
    return (
        <div id="Admin">
            <div className="admin-titre">
                <h1> Page d'Administration </h1> 
            </div>
            <div className="admin-data">
                <div className="admin-data-gauche">
                    <div className="admin-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="admin-data-gauche-menu">
                        <Link to="/admin/usersadd" className="admin-data-gauche-menu-lien"> Création d'un Utilisateur </Link>
                        <Link to="/admin/users" className="admin-data-gauche-menu-lien"> Gestion des Utilisateurs </Link> 
                        <Link to="/admin/photos" className="admin-data-gauche-menu-lien"> Gestion des Photos </Link> 
                        <Link to="/admin/assoc" className="admin-data-gauche-menu-lien"> Gestion des Associations </Link> 
                        <Link to="/admin/actus" className="admin-data-gauche-menu-lien"> Gestion des Actualités </Link> 
                        <Link to="/admin/events" className="admin-data-gauche-menu-lien"> Gestion des Evenements </Link> 
                        <Link to="/admin/docs" className="admin-data-gauche-menu-lien"> Gestion des Documents </Link> 
                        <Link to="/admin" className="admin-data-gauche-menu-lien"> Revenir Page d'admin </Link> 
                    </div>
                </div>
                <div className="admin-data-droite">
                    <div className="admin-data-droite-entete">
                        <h1> Tableau de Bord </h1>
                    </div>
                    <div className="admin-data-droite-list">
                        <div className="admin-data-droite-cadre">
                            <h1> Photos </h1>
                            <img alt="photos" src={photo}></img>
                            <Link to="/admin/photosadd" className="admin-data-droite-cadre-bouton-niv1"> Créer </Link>
                            <div className="admin-cadre-bouton">
                                <Link to="/admin/photos" className="admin-data-droite-cadre-bouton-niv2"> Liste </Link>
                                <Link to="/admin/photosadd" className="admin-data-droite-cadre-bouton-niv2"> Création </Link> 
                            </div>                       
                        </div>
                        <div className="admin-data-droite-cadre">
                            <h1> Evenements </h1>
                            <img alt="evenements" src={events}></img>
                            <Link to="/admin/eventsadd" className="admin-data-droite-cadre-bouton-niv1"> Créer </Link>
                            <div className="admin-cadre-bouton">
                                <Link to="/admin/events" className="admin-data-droite-cadre-bouton-niv2"> Liste  </Link>
                                <Link to="/admin/eventsadd" className="admin-data-droite-cadre-bouton-niv2"> Création </Link> 
                            </div>                      
                        </div>
                        <div className="admin-data-droite-cadre">
                            <h1> Actualités </h1>
                            <img src={actus} alt="actualites"></img>
                            <Link to="/admin/actusadd" className="admin-data-droite-cadre-bouton-niv1"> Créer </Link>
                            <div className="admin-cadre-bouton">
                                <Link to="/admin/actus" className="admin-data-droite-cadre-bouton-niv2"> Liste </Link>
                                <Link to="/admin/actusadd" className="admin-data-droite-cadre-bouton-niv2"> Création </Link> 
                            </div>
                        </div>
                        <div className="admin-data-droite-cadre">
                            <h1> Documents </h1>
                            <img src={docs} alt="documents"></img>
                            <Link to="/admin/docsadd" className="admin-data-droite-cadre-bouton-niv1"> Créer </Link>
                            <div className="admin-cadre-bouton">
                                <Link to="/admin/docs" className="admin-data-droite-cadre-bouton-niv2"> Liste </Link>
                                <Link to="/admin/docsadd" className="admin-data-droite-cadre-bouton-niv2"> Création </Link> 
                            </div>
                        </div>
                        <div className="admin-data-droite-cadre">
                            <h1> Textes </h1>
                            <img src={textes} alt="textes"></img>
                            <Link to="/admin/textesadd" className="admin-data-droite-cadre-bouton-niv1"> Créer </Link>
                            <div className="admin-cadre-bouton">
                                <Link to="/admin/textes" className="admin-data-droite-cadre-bouton-niv2"> Liste </Link>
                                <Link to="/admin/textesadd" className="admin-data-droite-cadre-bouton-niv2"> Création </Link> 
                            </div>
                        </div>
                        <div className="admin-data-droite-cadre">
                            <h1> Horaires </h1>
                            <img src={horaires} alt="horaires"></img>
                            <Link to="/admin/horairesadd" className="admin-data-droite-cadre-bouton-niv1"> Créer </Link>
                            <div className="admin-cadre-bouton">
                                <Link to="/admin/horaires" className="admin-data-droite-cadre-bouton-niv2"> Liste </Link>
                                <Link to="/admin/horairesadd" className="admin-data-droite-cadre-bouton-niv2"> Création </Link> 
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    )
    
}

export default Admin