import './HorairesAdd.css'
import { useState} from 'react'
import axios from 'axios'
import {Link } from 'react-router-dom'

function HorairesAdd ()
{
    const [semDepart, setsemDepart] = useState("")
    const [semFin, setsemFin] = useState("")
    const [ligne1, setLigne1] = useState("")
    const [ligne2, setLigne2] = useState("")

    const handleSemDepart = (event) => {
        setsemDepart(event.target.value)
    }

    const handleSemFin = (event) => {
        setsemFin(event.target.value)
    }

    const handleLigne1 = (event) => {
        setLigne1(event.target.value)
    }

    const handleLigne2 = (event) => {
        setLigne2(event.target.value)
    }

    const addHoraire = async () => {
        let data = {}

        data.semdepart = semDepart
        data.semfin = semFin
        data.ligne1 = ligne1
        data.ligne2 = ligne2

        let reponse = await axios.post('/api/horaires/create',{
            data:data
        })

        if(reponse.data === "ok")
        {
            alert("L'horaire a été ajouté")
            document.location.href = "/admin/horaires"
        }
    }

    return (
        <div className="HorairesAdd">
            <div className="horairesadd-titre">
                <h1> Ajouter un créneau horaire </h1> 
            </div>
            <div className="horairesadd-data">
                <div className="horairesadd-data-gauche">
                    <div className="horairesadd-data-gauche-entete">
                        <h1> Menu </h1>
                    </div>
                    <div className="horairesadd-data-gauche-menu">
                        <Link className="horairesadd-data-gauche-menu-lien" to="/admin/horaires"> Revenir à la page Actualités </Link>
                    </div>
                </div>
                <div className="horairesadd-data-droite">
                    <div className="horairesadd-form-titre">
                        <h1> Formulaire d'ajout </h1>     
                    </div>
                    <div className="horairesadd-form-data">
                        <div className="horairesadd-semaine">
                            <p> Semaine du </p> 
                            <input onChange={handleSemDepart} type="date"></input> 
                            <p> Au </p>
                            <input onChange={handleSemFin} type="date"></input> 
                        </div>
                        <div className="horairesadd-ligne1">
                            <p> Ligne 1 </p>
                            <textarea onChange={handleLigne1}></textarea>
                        </div>
                        <div className="horairesadd-ligne2">
                            <p> Ligne 2 </p>
                            <textarea onChange={handleLigne2}></textarea>
                        </div>
                    </div>
                    <div className="horairesadd-submit">
                        <button onClick={addHoraire}> Créer l'Horaire </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HorairesAdd