import axios from 'axios';
import { useEffect } from 'react'

function Logout () {

    useEffect(() => {
        logout()
    }, [])

    const logout = async () => {
        let reponse = await axios.get('/api/logout/')

        if(reponse.data === "logout")
        {
            
            document.location.href = "/"
        }
    }
    
    return(
        null
    )
    
}

export default Logout