import './ActusEdit.css'
import {Link, useParams} from 'react-router-dom'
import axios from 'axios'
import {useState, useEffect} from 'react'
import { Editor } from '@tinymce/tinymce-react';

function ActusEdit ()
{
    const [image, setImage] = useState()
    const [nomImage, setnomImage] = useState()
    const [fichier, setFichier] = useState()   
    const [nomfichier, setnomfichier] = useState()
    const [sysnomfichier, setsysnomfichier] = useState()
    const [typeactu, settypeactu] = useState()
    const [titreactu, settitreactu] = useState()
    const [textactu, settextactu] = useState()
    const [progressionImage, setProgressionImage] = useState()
    const [progressionFichier,setProgressionFichier] = useState()
   
    const { id } = useParams()

    //Gestion Fichier
    const handleFichier = (event) => {
        setFichier(event.target.files[0])
    }

    const sendFichierActu = (event) => {

        let data = new FormData()
        event.preventDefault()

        data.append('fichier', fichier)

        axios.post('/api/actus/addfile', data, {
            headers:{
                'Content-Type':'multipart/form-data'
            },
            onUploadProgress: async (progressEvent) => {
                setProgressionFichier(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        }).then(reponse => {
            setnomfichier(reponse.data.nom)
            setsysnomfichier(reponse.data.sysnom)
        })

    }

    //Gestion Image
    const handleImage = (event) => {
       
        setImage(event.target.files[0])
    }

    const sendImageActu = (event) => {

        let data = new FormData()
        event.preventDefault()
        
        data.append('image', image)

        if(typeactu === "principale")
        {
            axios.post('/api/actus/addprinc', data, {
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: async (progressEvent) => {
                    setProgressionImage(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(reponse => {
                setnomImage(reponse.data)
            })
        }
        else if(typeactu === "secondaire")
        {
            axios.post('/api/actus/addsecond', data, {
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: async (progressEvent) => {
                    setProgressionImage(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(reponse => {
                setnomImage(reponse.data)
            })
        }
       
    }

    const handleTitreActu = (event) => {
        settitreactu(event.target.value)
    }

    const handleTypeActu = (event) => {
        settypeactu(event.target.value)
    }

    const handleTexteActu = (value, editor) => {
        settextactu(editor.getContent())
    }

    const updateActu = () => {
        
        if(nomfichier !== undefined)
        {
            axios.post('/api/actus/update', {
                id:id,
                type: typeactu,
                nomimg : nomImage,
                nomfichier: nomfichier,
                sysnomfichier : sysnomfichier,
                text: textactu,
                titre: titreactu,
            }).then(reponse => {
                if(reponse.data === "ok")
                {
                    alert("L'actualité a été ajouté")
                    document.location.href = "/admin/actus"
                }
            })  
        }
        else
        {
            axios.post('/api/actus/update', {
                id:id,
                type: typeactu,
                nomimg : nomImage,
                text: textactu,
                titre: titreactu,
            }).then(reponse => {
                if(reponse.data === "ok")
                {
                    alert("L'actualité a été ajouté")
                    document.location.href = "/admin/actus"
                }
            })  
        }
    }

    useEffect(() => {
        axios.post('/api/actus/listbyid',{
            id: id
        }).then(reponse => {
            settextactu(reponse.data.text)
            settitreactu(reponse.data.titre)
            settypeactu(reponse.data.type)
            setnomImage(reponse.data.nomimg)
            setnomfichier(reponse.data.nomfichier)
            setsysnomfichier(reponse.data.sysnomfichier)
        })
    },[id])

    return (
        <div className="ActusEdit">
            <div className="actusedit-titre">
                <h1> Modifier une Actualité </h1> 
            </div>
            <div className="actusedit-data">
                <div className="actusedit-data-gauche">
                    <div className="actusedit-data-gauche-entete">
                        <h1> Menu </h1>
                    </div>
                    <div className="actusedit-data-gauche-menu">
                        <Link className="actusedit-data-gauche-menu-lien" to="/admin/actus"> Revenir à la page Actualités </Link>
                    </div>
                </div>
                <div className="actusedit-data-droite">
                    <div className="actusedit-form-titre">
                        <h1> Formulaire d'Edition </h1>     
                    </div> 
                    <div className="actusedit-form-data">
                        <p> Type d'Actualité </p>
                        <select value={typeactu} onChange={handleTypeActu}>
                            <option> Choisissez le type d'Actualité </option>
                            <option value="principale">Principale</option>
                            <option value="secondaire">Secondaire</option>
                        </select>
                        <p> Titre de l'Actualité  </p>
                        <input type="text" value={titreactu}  onChange={handleTitreActu}></input>
                        <p> Texte de l'Actualité </p>
                        <Editor
                            onEditorChange={handleTexteActu}
                            tinymceScriptSrc="/tinymce/tinymce.min.js"
                            value={textactu}
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                        <div className="actusedit-form-data-image">
                            <input type="file" placeholder="Selectionner une Image" onChange={handleImage}></input>
                            <button onClick={sendImageActu}> Charger l'Image </button>
                        </div>
                        {progressionImage &&
                            <p className="actusedit-loading"> Téléchargement en cours {progressionImage} % </p>
                        }
                        {nomImage && typeactu &&
                            <div className="actusedit-photosres">
                                <img alt="photores" src={"/images/"+nomImage}></img>
                            </div>
                        }
                        <div className="actusedit-form-data-fichier">
                            <h1> Charger un Ficher </h1>
                            <input type="file" placeholder="Selectionner une Image" onChange={handleFichier}></input>
                            <button onClick={sendFichierActu}> Charger le Fichier </button>
                        </div>
                        {progressionFichier &&
                            <p className="actusedit-loading"> Téléchargement en cours {progressionFichier} % </p>
                        }
                        {nomfichier && typeactu && 
                            <div className="actusedit-fileres">
                                <p>{nomfichier}</p> 
                            </div>
                        }
                    </div>
                    {typeactu && typeactu && textactu &&
                        <div className="actusedit-form-submit">
                            <button onClick={updateActu}> Modifier Actualité </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ActusEdit