import './Actus.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {useState, useEffect} from 'react'
import parse from 'html-react-parser'

function Actus ()
{
    const [actuPrinc, setactuPrinc] = useState()
    const [actuSecond, setactuSecond] = useState([])

    useEffect(() => {
        axios.get('/api/actus/listprinc').then(reponse => {
            setactuPrinc(reponse.data)
        })

        axios.get('/api/actus/listsecond').then(reponse => {
            setactuSecond(reponse.data)
        })
    },[])

    const goEdit = (event) => {

        let id = event.target.parentNode.getAttribute('value')

        document.location.href = "/admin/actusedit/"+id
    }

    const deleteActus = async (event) => {

        let id = event.target.parentNode.getAttribute('value')

        let reponse = await axios.post('/api/actus/delete', {
            id: id
        })

        if(reponse.data === "ok")
        {
            alert("L'actualité a été supprimée ")
            document.location.href = "/admin/actus"
        }
    }
  
    return (
        <div className="Actus">
            <div className="actus-titre">
                <h1>Gestion des actus</h1>
             </div>
            <div className="actus-data">
                <div className="actus-data-gauche">
                    <div className="actus-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="actus-data-gauche-menu">
                        <Link to="/admin" className="actus-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/actusadd" className="actus-data-gauche-menu-lien"> Ajouter des actus </Link> 
                    </div>
                </div>
                <div className="actus-data-droite">
                    <div className="actus-data-droite-titre">
                        <h1> Actualité Principale </h1>
                    </div>
                    {actuPrinc ? (
                        <div className="actus-princ">
                        <h1> {actuPrinc.titre} </h1>
                        <p>  {parse(actuPrinc.text)} </p>
                        <img alt="actus-princ-img" src={actuPrinc.cheminimg}></img> 
                        <div value={actuPrinc._id} className="actus-princ-actions">
                            <button> Supprimer </button>
                            <button onClick={goEdit}> Editer </button>
                        </div>
                    </div>
                    )
                    :
                    (
                        <div className="actus-princ">
                            <p> Pas d'Actualité </p>
                            <Link to="/admin/actusadd" className="actus-data-gauche-menu-lien"> Ajouter des actus </Link> 
                        </div>
                    )     
                    }
                    {actuSecond && 
                        <div classname="actus-second">
                            <div className="actus-second-titre">
                                <h1> Actualité Secondaire </h1> 
                            </div>
                            <div className="actus-second-data">
                            {actuSecond.map(actu => 
                                <div className="actus-second-ligne">
                                    <p> {actu.titre} </p>
                                    <p>  {parse(actu.text)} </p>
                                    <div className="actus-second-ligne-image">
                                        <img alt="actus-second-img" src={actu.cheminimg}></img> 
                                    </div>
                                    <div value={actu._id} className="actus-second-ligne-actions">
                                        <button onClick={deleteActus}> Supprimer </button>
                                        <button onClick={goEdit}> Editer </button>
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Actus