import './SousMenuMobile.css'
import {Link} from 'react-router-dom'

function SousMenuMobile (props) 
{
    const mouseOver = (event) => {

        event.target.style.backgroundColor = "orange"
    }

    const mouseLeave = (event) => {

        event.target.style.backgroundColor = "black"
        
        let liens = document.querySelectorAll(".sousmenumobile-princ-lien")

        liens.forEach(lien => {
            lien.style.backgroundColor = "black"
        })
    }

    const hideSousMenu = (event) => {
        props.issousmenumobile(false)
    }

    return (
        <div className="SousMenuMobile">
            <div className="sousmenumobile-princ">
                <div onMouseOver={mouseOver} onClick={hideSousMenu} onMouseLeave={mouseLeave} className="sousmenumobile-part">
                    <Link to="/" className="sousmenumobile-princ-lien">Accueil</Link>
                </div>
                <div onMouseOver={mouseOver} onClick={hideSousMenu} onMouseLeave={mouseLeave} className="sousmenumobile-part">
                    <Link to="/commune" className="sousmenumobile-princ-lien">Commune</Link>
                </div>
                <div onMouseOver={mouseOver} onClick={hideSousMenu} onMouseLeave={mouseLeave} className="sousmenumobile-part">
                    <Link to="/demarches" className="sousmenumobile-princ-lien">Demarches</Link>
                </div>
            </div>
        </div>
    )
}

export default SousMenuMobile