import './Events.css'
import { Link } from 'react-router-dom'
import {useState,useEffect} from 'react'
import axios from 'axios'

function Events ()
{
    const [events, setEvents] = useState([])

    useEffect(() => {
        axios.get('/api/events/list').then(reponse => {
            setEvents(reponse.data)
        })
    },[])

    const deleteEvent = (event) => {

        let id = event.target.parentNode.getAttribute('value')

        axios.post('/api/events/delete', {
            id : id
        }).then(reponse => {
            if(reponse.data === "ok")
            {
                alert("L'évènement a été supprimé")
                document.location.href = "/admin/events"
            }
        })
    }

    const goEdit = (event) => {

        let id = event.target.parentNode.getAttribute('value')

        document.location.href = "/admin/eventsedit/"+id
    }

    return (
        <div className="Events">
            <div className="events-titre">
                <h1> Gestion des Evenements </h1>
             </div>
            <div className="events-data">
                <div className="events-data-gauche">
                    <div className="events-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="events-data-gauche-menu">
                        <Link to="/admin" className="events-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/eventsadd" className="events-data-gauche-menu-lien"> Ajouter des Evenements </Link> 
                    </div>
                </div>
                <div className="events-data-droite">
                    <div className="events-data-droite-titre">
                        <h1> Liste des Evenements </h1>
                    </div>
                    <div className="events-list">
                        {events.map(ligne =>
                            <div key={ligne._id} value={ligne._id} className="event-ligne">
                                <div className="event-ligne-titre">
                                    <p> {ligne.titre} </p>
                                </div>
                                <img alt="events-chemin" src={"/images/"+ligne.nomimg}></img>
                                <div value={ligne._id} className="event-ligne-actions">
                                    <button onClick={goEdit}> Modifier </button>
                                    <button onClick={deleteEvent}> Supprimer </button>
                                </div>
                            </div>       
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Events