import './HorairesEdit.css'
import { useState, useEffect } from 'react'
import axios from 'axios'
import {Link, useParams } from 'react-router-dom'

function HorairesEdit ()
{
    const { id } = useParams()

    const [semDepart, setsemDepart] = useState("")
    const [semFin, setsemFin] = useState("")
    const [ligne1, setLigne1] = useState("")
    const [ligne2 , setLigne2] = useState("")

    const handleSemDepart = (event) => {
        setsemDepart(event.target.value)
    }

    const handleSemFin = (event) => {
        setsemFin(event.target.value)
    }

    const handleLigne1 = (event) => {
        setLigne1(event.target.value)
    }

    const handleLigne2 = (event) => {
        setLigne2(event.target.value)
    }

    const updateHoraire = async () => {
        let data = {}

        data.id = id
        data.semdepart = semDepart
        data.semfin = semFin
        data.ligne1 = ligne1
        data.ligne2 = ligne2

        let reponse = await axios.post('/api/horaires/update',{
            data:data
        })

        if(reponse.data === "ok")
        {
            alert("L'horaire a été mise à jour")
            document.location.href = "/admin/horaires"
        }
    }

    useEffect(() => {
        axios.post('/api/horaires/listbyid', {
            id: id   
        }).then(reponse => {
            setsemDepart(reponse.data.semdepart)
            setsemFin(reponse.data.semfin)
            setLigne1(reponse.data.ligne1)
            setLigne2(reponse.data.ligne2)
        })
    },[id])

    return (
        <div className="HorairesEdit">
            <div className="horairesedit-titre">
                <h1> Ajouter un créneau horaire </h1> 
            </div>
            <div className="horairesedit-data">
                <div className="horairesedit-data-gauche">
                    <div className="horairesedit-data-gauche-entete">
                        <h1> Menu </h1>
                    </div>
                    <div className="horairesedit-data-gauche-menu">
                        <Link className="horairesedit-data-gauche-menu-lien" to="/admin/horaires"> Revenir à la page Actualités </Link>
                    </div>
                </div>
                <div className="horairesedit-data-droite">
                    <div className="horairesedit-form-titre">
                        <h1> Formulaire d'ajout </h1>     
                    </div>
                    <div className="horairesedit-form-data">
                        <div className="horairesadd-semaine">
                            <p> Semaine du </p> 
                            <input value={semDepart} onChange={handleSemDepart} type="date"></input> 
                            <p> Au </p>
                            <input value={semFin} onChange={handleSemFin} type="date"></input> 
                        </div>
                        <div className="horairesadd-ligne1">
                            <p> Ligne 1 </p>
                            <textarea value={ligne1} onChange={handleLigne1}></textarea>
                        </div>
                        <div className="horairesadd-ligne2">
                            <p> Ligne 2 </p>
                            <textarea value={ligne2} onChange={handleLigne2}></textarea>
                        </div>
                    </div>
                    <div className="horairesedit-submit">
                        <button onClick={updateHoraire}> Valider les modifications </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HorairesEdit