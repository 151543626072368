import './TextesEdit.css'
import {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react';

function TextesEdit ()
{
    const [texteTitre, settexteTitre] = useState()
    const [texteCategorie, settexteCategorie] = useState()
    const [texteEmplacement, settexteEmplacement] = useState()
    const [texteContenu, settexteContenu] = useState()

    const { id } = useParams()
  
    const handleTexteTitre = (event) => {
        settexteTitre(event.target.value)
    }

    const handleTexteCategorie = (event) => {
        settexteCategorie(event.target.value)
    }

    const handleTexteEmplacement = (event) => {
        settexteEmplacement(event.target.value)
    }
    
    const handleTexteContenu = (value, editor) => {
        settexteContenu(editor.getContent())
    }

    const updateTexte = () => {

        let data = {}

        data.id = id
        data.titre = texteTitre
        data.contenu = texteContenu
        data.categorie = texteCategorie
        data.emplacement = texteEmplacement
      
        axios.post('/api/textes/update', {
            data: data
        }).then(reponse => {
            if(reponse.data === "ok")
            {
                alert("Le texte a été modifié")
                document.location.href = "/admin/textes"
            }
        })
    }

    useEffect(() => {
        axios.post('/api/textes/listbyid',{
            id: id
        }).then(reponse => {
            settexteCategorie(reponse.data.categorie)
            settexteContenu(reponse.data.contenu)
            settexteEmplacement(reponse.data.emplacement)
            settexteTitre(reponse.data.titre)
        })
    },[id])
        
    return(
        <div className="TextesEdit">
            <div className="textesedit-titre">
                <h1> Gestion des Documents </h1>
             </div>
            <div className="textesedit-data">
                <div className="textesedit-data-gauche">
                    <div className="textesedit-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="textesedit-data-gauche-menu">
                        <Link to="/admin" className="textesedit-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/textes" className="textesedit-data-gauche-menu-lien"> Revenir Liste Textes </Link> 
                    </div>
                </div>
                <div className="textesedit-data-droite">
                    <div className="textesedit-data-droite-titre">
                        <h1> Mise à jour d'un Texte </h1>
                    </div>
                    <div className="textesedit-data-droite-form">
                        <p>Titre du Texte</p>
                        <input value={texteTitre} onChange={handleTexteTitre} type="text"></input>
                        <p>Categorie du Texte</p>
                        <select value={texteCategorie} onChange={handleTexteCategorie}>
                            <option> Choisir une Categorie </option>
                            <option> Popup </option>
                            <option> Texte </option> 
                        </select>
                        <p> Emplacement du Texte </p>
                        <select value={texteEmplacement} onChange={handleTexteEmplacement}>
                            <option> Choisir un Emplacement </option>
                            <option> Accueil </option>
                            <option> Commune </option>
                            <option> Demarches </option>
                        </select>
                        <p> Contenu du Texte </p>
                        <Editor
                            onEditorChange={handleTexteContenu}
                            apiKey="qifsiwswq3vrcopauh2tkk08b6oudvor89zqj7a67u6x983n"
                            value={texteContenu}
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>
                    <div className="textesedit-data-droite-form-submit">
                        <button onClick={updateTexte}> Modifier un Texte </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TextesEdit