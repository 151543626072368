import './Menu.css'
import { Link } from 'react-router-dom'
import logo from '../../Image/logo.png'
//import cadenas from '../../Images/cadenas.png'
import {useEffect, useState, useCallback} from 'react'
import MenuMobile from './MenuMobile'
import SousMenuMobile from './SousMenuMobile'
import {useMediaQuery} from 'react-responsive'

function Menu () 
{
    const isMobileDevice = useMediaQuery({
        maxWidth: 600
    });
 
    const isTabletPortrait = useMediaQuery({
        minWidth: 601, maxWidth:1200
    })

    /*
    const isTabletLandScape = useMediaQuery({
        minWidth: 991, maxWidth:1500
    })*/

    const [isSousMenuMobile, setisSousMenuMobile] = useState(false)
    
    const menuSticky = useCallback(() => {

        let menu = ""

        if(isMobileDevice)
        {
            menu = document.querySelectorAll('.menu-racine')

            //Menu 
            menu[0].style.position = "fixed"
            menu[0].style.top = "0"
            menu[0].style.left = "0"
            menu[0].style.zIndex = "30" 
            menu[0].style.position = "relative"   
        }
        else if(isTabletPortrait)
        {
            menu = document.querySelectorAll('.menu-racine')
            
            //Menu 
            menu[0].style.position = "fixed"
            menu[0].style.top = "0"
            menu[0].style.left = "0"
            menu[0].style.zIndex = "10" 
            menu[0].style.position = "relative"
        }
        else
        {
            menu = document.querySelectorAll('.menu-data')
        }

        window.onscroll = () => {
            
            if(window.scrollY > 260)
            {
                //Menu 
                menu[0].style.position = "fixed"
                menu[0].style.top = "0"
                menu[0].style.left = "0"
                menu[0].style.zIndex = "10"    
            }
            else
            {
                menu[0].style.position = ""
            }
            
        }
    },[isMobileDevice,isTabletPortrait])

    useEffect (() => {

        menuSticky()
                
    },[menuSticky])
        
    return(
        <div className="Menu">  
            {   isMobileDevice ?
                (
                    <div className="menu-racine">
                        <MenuMobile data={isSousMenuMobile} issousmenumobile={isSousMenuMobile => setisSousMenuMobile(isSousMenuMobile)}></MenuMobile>
                        {isSousMenuMobile &&
                            
                            <SousMenuMobile issousmenumobile={isSousMenuMobile => setisSousMenuMobile(isSousMenuMobile)}></SousMenuMobile>
                        }    
                    </div>            
                ):
                isTabletPortrait ?
                (
                    <div className="menu-racine">
                        <MenuMobile data={isSousMenuMobile} issousmenumobile={isSousMenuMobile => setisSousMenuMobile(isSousMenuMobile)}></MenuMobile>
                        {isSousMenuMobile &&
                            
                            <SousMenuMobile issousmenumobile={isSousMenuMobile => setisSousMenuMobile(isSousMenuMobile)}></SousMenuMobile>
                        }    
                    </div>  
                )
                :
                (
                    <div className="menu-data">
                        <div className="menu-gauche">
                            <Link to="/" className="menu-lien"> Accueil </Link>               
                            <Link to="/commune" className="menu-lien"> La Commune </Link>
                        </div>
                        <div className="menu-centre">
                            <img alt="menucentre" src={logo}></img>
                        </div>
                        <div className="menu-droite">
                            <Link to="/demarches" className="menu-lien">  Vos Demarches </Link>
                            <Link to="/login" className="menu-lien">  Espace Mairie </Link>
                        </div>
                    </div>
                )
            }           
        </div>
    )
    
}

export default Menu