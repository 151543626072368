import './PopupPolitique.css'
import imgquit from '../../Image/quit.png'

function PopupPolitique (props) 
{ 
    const quitPopup = () => {
        props.isclose(false)
    
    }

    return (
        <div id="PopupPolitique">
            <div className="popup-politique-data">
                <div className="popup-politique-data-titre">
                    <h1> Politique de Cookies </h1>
                </div>
                <div className="popup-politique-data-form">
                    <img alt="popup-politique-data-img-quit" onClick={quitPopup} className="popup-politique-data-img-quit" src={imgquit}></img>
                    <p> Ce site utilise des cookies </p>
                    <div className="popup-politique-actions">
                        <button onClick={quitPopup}> Accepter les Cookies </button>
                        <button onClick={quitPopup}> Refuser les Cookies </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupPolitique