import './DocsAdd.css'
import { Link } from 'react-router-dom'
import {useState} from 'react'
import axios from 'axios'

function DocsAdd ()
{
    const [fichiers, setFichiers] = useState([])
    const [fichiersFinaux, setfichiersFinaux] = useState([])
    const [typeFichier, setTypeFichier] = useState()
    const [progression, setProgression] = useState()
    
    const handleFichiers = (event) => {

        setFichiers(event.target.files)
    }

    const handleTypeFichier = (event) => {
        setTypeFichier(event.target.value)
    }

    const sendFiles = (event) => {

        let data = new FormData()

        for(let i = 0; i < fichiers.length ; i++)
        {
            data.append('fichiers', fichiers[i])
        }

       
        axios.post('/api/docs/addfiles', data, {
            headers:{
                'Content-Type':'multipart/form-data'
            },
            onUploadProgress: async (progressEvent) => {
                setProgression(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        }).then(reponse => {
            setfichiersFinaux(reponse.data)
        })
        
    }

    const addDocuments = () => {

        let data = {}

        data.fichiers = fichiersFinaux
        data.type = typeFichier

        axios.post('/api/docs/create', {
            data : data
        }).then(reponse => {
            if(reponse.data === "ok")
            {
                alert("Les documents ont été ajoutés")
                document.location.href = "/admin/docs"
            }
        })
    }

    return(
        <div className="DocsAdd">
            <div className="docsadd-titre">
                <h1> Gestion des Documents </h1>
             </div>
            <div className="docsadd-data">
                <div className="docsadd-data-gauche">
                    <div className="docsadd-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="docsadd-data-gauche-menu">
                        <Link to="/admin" className="docsadd-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/docs" className="docsadd-data-gauche-menu-lien"> Revenir Liste Documents </Link> 
                    </div>
                </div>
                <div className="docsadd-data-droite">
                    <div className="docsadd-data-droite-titre">
                        <h1> Ajout d'un document </h1>
                    </div>
                    <div className="docsadd-form">
                        <p> Type de Document </p>
                        <select onChange={handleTypeFichier}> 
                            <option> Choisir un Type de Fichier </option> 
                            <option value="ar"> Avis de Réunion </option>
                            <option value="cr"> Compte Rendu Conseil </option>
                        </select>
                        <div>
                            <input type="file" onChange={handleFichiers} placeholder="choisir un fichier" multiple></input>
                            <button onClick={sendFiles}> Charger les Fichiers </button>
                        </div>
                        {progression &&
                            <p className="photosadd-loading"> Téléchargement en cours {progression} % </p>
                        }
                        {fichiersFinaux.map(fichier => 
                            <div>
                                <p> {fichier.nom} </p>
                            </div>    
                        )}
                        {fichiersFinaux.length > 0 &&
                            <div>
                                <button onClick={addDocuments}> Ajouter le ou les fichiers </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocsAdd