import './Footer.css'
import horaires from '../../Image/lhorloge.png'
import { useState, useEffect} from 'react'
import axios from "axios"

function Footer () 
{
    const [horairesList, setHorairesList] = useState([])

    useEffect(() => {
       
        axios.get('/api/horaires/list').then(reponse => {
            setHorairesList(reponse.data)
        })

    },[])

    const goToAccueil = () => {
        document.location.href = "/"
    }

    const goToCommune = () => {
        document.location.href = "/commune"
    }

    const goToDemarches = () => {
        document.location.href = "/demarches"
    }

    const goToPolitique = (event) => {

        axios.get('/api/docs/getpolite', {
            responseType:"blob",
        }).then(reponse => {
            
            const file = new Blob([reponse.data], {type:'application/pdf'})

            const fileurl = URL.createObjectURL(file)

            window.open(fileurl)
        })
    }

    const goToMention = (event) => {

        axios.get('/api/docs/getment', {
            responseType:"blob",
        }).then(reponse => {
            
            const file = new Blob([reponse.data], {type:'application/pdf'})

            const fileurl = URL.createObjectURL(file)

            window.open(fileurl)
        })
    }

    return (
        <div className="Footer">
            <div className="footer-gauche">
                <h1>Savonnières-devant-Bar</h1>
                <img alt="footer-horaires" src={horaires}></img>
                {horairesList.map(horaire => 
                        <div key={horaire._id} className="footer-horaire-ligne">
                            <p> Semaine du {new Date(horaire.semdepart).toLocaleDateString()} au {new Date(horaire.semfin).toLocaleDateString()} </p>
                            <p> La mairie est ouverte : </p> 
                            <p> {horaire.ligne1} </p>
                            <p> {horaire.ligne2} </p>
                        </div>
                    )   
                }              
            </div>
            <div className="footer-milieu">
                <h1> Plan du site </h1>
                <p className="footer-milieu-liens" onClick={goToAccueil}> Accueil </p>
                <p className="footer-milieu-liens" onClick={goToCommune}> Commune </p>
                <p className="footer-milieu-liens" onClick={goToDemarches}> Démarches </p>
                <p className="footer-milieu-liens" onClick={goToMention}> Mentions Légales </p>
                <p className="footer-milieu-liens" onClick={goToPolitique}> Politique de confidentialité</p>
            </div>
            <div className="footer-droite">
                <h1>Coordonnées</h1>
                <p>Mairie de Savonnières-devant-Bar</p>
                <p>1 Rue Alexandre Violle</p>
                <p>55000 Savonnières-devant-Bar</p>
                <p>mairie-savonnieres@orange.fr</p>
                <p>03 29 79 10 04</p>
            </div>
        </div>
    )
}

export default Footer
