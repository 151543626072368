import './Accueil.css'
import {useState, useEffect} from 'react'
import axios from 'axios'
import notice from '../Image/notice.png'
import phconseil from '../Image/conseilsavo.JPG'
import report from '../Image/report.png'
import Diaporama from '../Composants/Diaporama/Diaporama'
import parse from 'html-react-parser'
import Popup from './Popup/Popup'

function Accueil ()
{
    const [tabdiapos, setTabDiapos] = useState([])
    const [actuPrincipale ,setactuPrincipale] = useState()
    const [actuSecondaire, setactuSecondaire] = useState([])
    const [tabevents, setTabEvents] = useState([])
    const [datapopmuni, setdatapopmuni] = useState() 
    const [isPopupmuni, setisPopupmuni] = useState(false)
    
    useEffect(() => {
        axios.get('/api/photos/listaccueil').then(reponse => {
            setTabDiapos(reponse.data)
        })

        axios.get('/api/actus/listprinc').then(reponse => {
            setactuPrincipale(reponse.data)
        })

        axios.get('/api/actus/listsecond').then(reponse => {
            setactuSecondaire(reponse.data)

        })

        axios.get('/api/events/list').then(reponse => {
            setTabEvents(reponse.data)
        })
        
        axios.post('/api/textes/listbycode', {
            code: "TXT2205-200"
        }).then(reponse => {
            setdatapopmuni(reponse.data)
        })

    },[])

    const displayPopupmuni = () => {
        setisPopupmuni(true)
    }

    const goDoc = () => {
        document.location.href = "/docs"
    }

    return (
        <div className="Accueil">
            <Diaporama data={tabdiapos} classdiapos="diapos" classslider="slider"></Diaporama>
            <div className="accueil-bienvenue">
                <h1>Bienvenue à Savonnières-Devant-Bar</h1>
            </div>
            <div className="accueil-une">
                <div className="accueil-une-titre">
                    <h1>A la Une...</h1>
                </div>
                <div className="accueil-une-data">
                    {actuPrincipale &&
                        <div className="accueil-une-data-gauche">
                            <img alt="actuprincipale-img" src={actuPrincipale.cheminimg}></img>
                        </div>
                    }
                    {actuPrincipale &&
                        <div className="accueil-une-data-droite">
                        <h1> {actuPrincipale.titre} </h1>
                        <p> {parse(actuPrincipale.text)}</p>
                        {actuPrincipale.sysnomfichier &&
                            <a href={"/documents/"+actuPrincipale.sysnomfichier}>En savoir plus</a>
                        }
                        </div>
                    }  
                </div>
            </div>
            <div className="accueil-actu">
                <div className="accueil-actu-haut">
                    <h1>Les autres actualités à Savonnières-devant-Bar</h1>
                </div>
                <div className="accueil-actu-bas">
                {actuSecondaire.map(actu => 
                    <div key={actu._id} className="accueil-actu-bas-cadre">
                        <img src={actu.cheminimg} alt='collecte'></img>
                        <h1>{actu.titre}</h1>
                        <p> {parse(actu.text)}</p>
                        {actu.sysnomfichier &&
                            <a href={"/documents/"+actu.sysnomfichier}>En savoir plus</a>
                        }
                    </div>
                )}
                </div>
            </div>
            <div className="accueil-municipal-titre">
                <h1>LE CONSEIL MUNICIPAL</h1>
            </div>
            <div className="accueil-municipal">
                <img alt="" src={phconseil}></img>
            </div>
            {isPopupmuni &&
                <Popup isclose={isPopupmuni => setisPopupmuni(isPopupmuni)} data={datapopmuni}></Popup>
            }
            <div className="accueil-avis">
                <div className="accueil-avisG">
                    <h1>L'Equipe Municipale</h1>
                    <img onClick={displayPopupmuni} src={phconseil} alt='phconseil'></img>
                </div>
                <div className="accueil-avisM">
                    <h1>Avis de Reunion</h1>
                    <img onClick={goDoc} src={notice} alt='notice'></img>
                </div>

                <div className="accueil-avisD">
                    <h1>Comptes-rendus du Conseil</h1>
                    <img onClick={goDoc} src={report} alt='report'></img>
                </div>
            </div>  
            <div className="accueil-events">
                <div className="accueil-events-titre">
                    <h1> Evènements </h1> 
                </div>
                <div className="accueil-events-data">
                    {tabevents.map(event =>
                        <div key={event._id} className="accueil-events-cadre">
                            <div className="accueil-events-cadre-titre">
                                <p>{event.titre} </p>
                            </div>
                            <div className="accueil-events-cadre-data">
                                <img alt="cadre-img" src={"/images/"+event.nomimg}></img>
                            </div>
                        </div>
                    )}      
                </div>
            </div>
               
        </div>
    )
}

export default Accueil