import './EventsEdit.css'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'

function EventsEdit ()
{
    const [image, setImage] = useState()
    const [nomImage, setnomImage] = useState()
    const [titreevent, settitreevent] = useState()
    const [progression, setProgression] = useState()

    const { id } = useParams()

    const handleImage = (event) => {
       
        setImage(event.target.files[0])
    }

    const sendImageEvent = (event) => {
        
        let data = new FormData()
        event.preventDefault()

        data.append('image', image)

        axios.post('/api/events/addevimg', data, {
            headers:{
                'Content-Type':'multipart/form-data'
            },
            onUploadProgress: async (progressEvent) => {
                setProgression(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        }).then(reponse => {
            setnomImage(reponse.data)
        })
       
    }

    const handleTitreEvent = (event) => {
        settitreevent(event.target.value)
    }

    const updateEvent = () => {
        axios.post('/api/events/update', {
            id: id,
            nomimg : nomImage,
            titre: titreevent,
        }).then(reponse => {
            if(reponse.data === "ok")
            {
                alert("L'evènement a été ajouté")
                document.location.href = "/admin/events"
            }
        })
    }

    useEffect(() => {
        axios.post('/api/events/listbyid', {
            id: id
        }).then(reponse => {
            settitreevent(reponse.data.titre)
            setnomImage(reponse.data.nomimg)
        })
    },[id])

    return (
        <div className="EventsEdit">
            <div className="eventsedit-titre">
                <h1> Editer un Evenement </h1> 
            </div>
            <div className="eventsedit-data">
                <div className="eventsedit-data-gauche">
                    <div className="eventsedit-data-gauche-entete">
                        <h1> Menu </h1>
                    </div>
                    <div className="eventsedit-data-gauche-menu">
                        <Link className="eventsedit-data-gauche-menu-lien" to="/admin/events"> Revenir à la page Evenements </Link>
                    </div>
                </div>
                <div className="eventsedit-data-droite">
                    <div className="eventsedit-form-titre">
                        <h1> Formulaire de Modification </h1>     
                    </div> 
                    <div className="eventsedit-form-data">
                        <p> Titre de l'Evenement </p>
                        <input value={titreevent} type="text" onChange={handleTitreEvent}></input>
                        <div className="eventsedit-form-data-image">
                            {nomImage &&
                                <div className="eventsedit-photosres">
                                    <img alt="eventsimg" src={"/images/"+nomImage}></img>
                                </div>
                            }
                            <p> Charger une nouvelle Image </p>
                            <input type="file" placeholder="Selectionner une Image" onChange={handleImage}></input>
                            {image &&
                                <button onClick={sendImageEvent}> Charger l'Image </button>
                            }
                        </div>
                        {progression &&
                            <p className="eventsedit-loading"> Téléchargement en cours {progression} % </p>
                        }
                        
                    </div>
                    {nomImage && titreevent &&
                        <div className="eventsedit-form-submit">
                            <button onClick={updateEvent}> Modifier </button>
                        </div>
                    }   
                </div>
            </div>
        </div>
    )
}

export default EventsEdit