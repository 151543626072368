import './AssocEdit.css'
import {Link, useParams} from 'react-router-dom'
import axios from 'axios'
import {useState, useEffect} from 'react'
import { Editor } from '@tinymce/tinymce-react';

function AssocEdit ()
{
    const [image, setImage] = useState()
    const [nomImage, setnomImage] = useState()
    const [titreassoc, settitreassoc] = useState("")
    const [textassoc, settextassoc] = useState("")
    const [progressionImage, setProgressionImage] = useState()
       
    const { id } = useParams()

    //Gestion Image
    const handleImage = (event) => {
       
        setImage(event.target.files[0])
    }

    const sendImageAssoc = (event) => {

        let data = new FormData()
        event.preventDefault()
        
        data.append('image', image)

        axios.post('/api/assocs/imageadd', data, {
            headers:{
                'Content-Type':'multipart/form-data'
            },
            onUploadProgress: async (progressEvent) => {
                setProgressionImage(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        }).then(reponse => {
            setnomImage(reponse.data)
        })
       
    }

    const handleTitreAssoc = (event) => {
        settitreassoc(event.target.value)
    }

    const handleTexteAssoc = (value, editor) => {
        settextassoc(editor.getContent())
    }

    const updateAssoc = () => {
        
        axios.post('/api/assoc/update', {
            id:id,
            nomimg : nomImage,
            text: textassoc,
            titre: titreassoc,
        }).then(reponse => {
            if(reponse.data === "ok")
            {
                alert("L'association a été mise à jour")
                document.location.href = "/admin/assoc"
            }
        })  
       
    }

    useEffect(() => {
        axios.post('/api/assoc/listbyid',{
            id: id
        }).then(reponse => {
            settextassoc(reponse.data.text)
            settitreassoc(reponse.data.titre)
            setnomImage(reponse.data.nomimg)
        })
    },[id])

    return (
        <div className="AssocEdit">
            <div className="assocedit-titre">
                <h1> Modifier une Actualité </h1> 
            </div>
            <div className="assocedit-data">
                <div className="assocedit-data-gauche">
                    <div className="assocedit-data-gauche-entete">
                        <h1> Menu </h1>
                    </div>
                    <div className="assocedit-data-gauche-menu">
                        <Link className="assocedit-data-gauche-menu-lien" to="/admin/assoc"> Revenir à la page Associations </Link>
                    </div>
                </div>
                <div className="assocedit-data-droite">
                    <div className="assocedit-form-titre">
                        <h1> Formulaire d'Edition </h1>     
                    </div> 
                    <div className="assocedit-form-data">
                        <p> Titre de l'Association   </p>
                        <input type="text" value={titreassoc}  onChange={handleTitreAssoc}></input>
                        <p> Texte de l'Association </p>
                        <Editor
                            onEditorChange={handleTexteAssoc}
                            tinymceScriptSrc="/tinymce/tinymce.min.js"
                            value={textassoc}
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                        <div className="assocedit-form-data-image">
                            <input type="file" placeholder="Selectionner une Image" onChange={handleImage}></input>
                            <button onClick={sendImageAssoc}> Charger l'Image </button>
                        </div>
                        {progressionImage &&
                            <p className="assocedit-loading"> Téléchargement en cours {progressionImage} % </p>
                        }
                        {nomImage && 
                            <div className="assocedit-photosres">
                                <img alt="photores" src={"/images/"+nomImage}></img>
                            </div>
                        }
                    </div>
                    {textassoc &&
                        <div className="assocedit-form-submit">
                            <button onClick={updateAssoc}> Modifier Association </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AssocEdit