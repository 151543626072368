import './Horaires.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {useState, useEffect} from 'react'

function Horaires ()
{
    const [horaires, setHoraires] = useState([])

    useEffect(() => {
       
        axios.get('/api/horaires/list').then(reponse => {
            setHoraires(reponse.data)
        })

    },[])

    const goEdit = (event) => {

        let id = event.target.parentNode.getAttribute('value')

        document.location.href = "/admin/horairesedit/"+id
    }

    const deleteHoraires = async (event) => {

        let id = event.target.parentNode.getAttribute('value')

        let reponse = await axios.post('/api/horaires/deletebyid', {
            id: id
        })

        if(reponse.data === "ok")
        {
            alert("L'Horaire a été supprimé")
            document.location.href = "/admin/horaires"
        }
    }
  
    return (
        <div className="Horaires">
            <div className="horaires-titre">
                <h1>Gestion des horaires</h1>
             </div>
            <div className="horaires-data">
                <div className="horaires-data-gauche">
                    <div className="horaires-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="horaires-data-gauche-menu">
                        <Link to="/admin" className="horaires-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        {horaires && horaires.length === 0 &&
                            <Link to="/admin/horairesadd" className="horaires-data-gauche-menu-lien"> Ajouter des horaires </Link> 
                        }
                    </div>
                </div>
                <div className="horaires-data-droite">
                    <div className="horaires-data-droite-titre">
                        <p> Date Semaine Départ </p>
                        <p> Date Semaine Fin </p>
                        <p> Ligne 1 Horaire </p>
                        <p> Ligne 2 Horaire </p>
                        <p> Action </p> 
                    </div>
                    {horaires.map(horaire =>
                            <div key={horaire._id} className="horaire-ligne">
                                <p> {new Date(horaire.semdepart).toLocaleDateString() }</p>
                                <p> {new Date(horaire.semfin).toLocaleDateString() }</p>
                                <p> {horaire.ligne1} </p>
                                <p> {horaire.ligne2} </p>
                                <div value={horaire._id}>
                                    <button onClick={goEdit}> Modifier </button>
                                    <button onClick={deleteHoraires}> Supprimer </button>
                                </div>
                            </div>
                        )
                    }
                </div> 
            </div>
        </div>
    )
}

export default Horaires