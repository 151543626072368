import './Cookies.css'
import { useState, useEffect, useRef } from 'react'
import {useMediaQuery} from 'react-responsive'
import PopupPolitique from '../../Popup/PopupPolitique'

function Cookies ()
{
    const cookies = useRef()
    const [ispolitiquePopup, setisPolitiquePopup] = useState()
    
    const isMobileDevice = useMediaQuery({
        maxWidth: 600
    });
 
    const isTabletPortrait = useMediaQuery({
        minWidth: 601, maxWidth:1200
    })
  
    const displayPopup = () => {
        setisPolitiquePopup(true)
    }

    useEffect(() => {

        console.log(window.innerHeight)

        window.onscroll = () => {
            if(window.scrollY > 150 && !isMobileDevice && !isTabletPortrait)
            {
                cookies.current.style.display = "flex";
                cookies.current.style.position = "fixed";
                cookies.current.style.right = 0;
                cookies.current.style.width = "25px";
                cookies.current.style.height = "180px";
                cookies.current.style.borderRadius = "2px";
            }
            else
            {
                cookies.current.style.display = "none"
            }
        }
    },[isMobileDevice,isTabletPortrait])

    return (
        <div ref={cookies} className="Cookies">
            {ispolitiquePopup &&
                <PopupPolitique isclose={ispolitiquePopup => setisPolitiquePopup(ispolitiquePopup)}></PopupPolitique>
            }
            <div onClick={displayPopup} className="cookies-cadre">
                <p onClick={displayPopup}> Politique de Cookies </p>    
            </div>       
        </div>
    )
}

export default Cookies