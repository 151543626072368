import './TextesAdd.css'
import { Link } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react';
import {useState} from 'react'
import axios from 'axios';

function TextesAdd ()
{
    const [texteTitre, settexteTitre] = useState()
    const [texteCategorie, settexteCategorie] = useState()
    const [texteEmplacement, settexteEmplacement] = useState()
    const [texteContenu, settexteContenu] = useState()
  
    const handleTexteTitre = (event) => {
        settexteTitre(event.target.value)
    }

    const handleTexteCategorie = (event) => {
        settexteCategorie(event.target.value)
    }

    const handleTexteEmplacement = (event) => {
        settexteEmplacement(event.target.value)
    }
    
    const handleTexteContenu = (value, editor) => {
        settexteContenu(editor.getContent())
    }

    const envoiTexte = () => {

        let data = {}

        data.titre = texteTitre
        data.contenu = texteContenu
        data.categorie = texteCategorie
        data.emplacement = texteEmplacement
      
        axios.post('/api/textes/create', {
            data: data
        }).then(reponse => {
            if(reponse.data === "ok")
            {
                alert("Le texte a été crée")
                document.location.href = "/admin/textes"
            }
        })
    }
        
    return(
        <div className="TextesAdd">
            <div className="textesadd-titre">
                <h1> Gestion des Documents </h1>
             </div>
            <div className="textesadd-data">
                <div className="textesadd-data-gauche">
                    <div className="textesadd-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="textesadd-data-gauche-menu">
                        <Link to="/admin" className="textesadd-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/textes" className="textesadd-data-gauche-menu-lien"> Revenir Liste Textes </Link> 
                    </div>
                </div>
                <div className="textesadd-data-droite">
                    <div className="textesadd-data-droite-titre">
                        <h1> Ajout d'un Texte </h1>
                    </div>
                    <div className="textesadd-data-droite-form">
                        <p>Titre du Texte</p>
                        <input onChange={handleTexteTitre} type="text"></input>
                        <p>Categorie du Texte</p>
                        <select onChange={handleTexteCategorie}>
                            <option> Choisir une Categorie </option>
                            <option> Popup </option>
                            <option> Texte </option> 
                        </select>
                        <p> Emplacement du Texte </p>
                        <select onChange={handleTexteEmplacement}>
                            <option> Choisir un Emplacement </option>
                            <option> Accueil </option>
                            <option> Commune </option>
                            <option> Demarches </option>
                        </select>
                        <p> Contenu du Texte </p>
                        <Editor
                            onEditorChange={handleTexteContenu}
                            apiKey="qifsiwswq3vrcopauh2tkk08b6oudvor89zqj7a67u6x983n"
                            value={texteContenu}
                            init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                            ],
                            toolbar: 'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        />
                    </div>
                    <div className="textesadd-data-droite-form-submit">
                        <button onClick={envoiTexte}> Ajouter un Texte </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TextesAdd