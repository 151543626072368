import './Docs.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useState, useEffect } from 'react'

function Docs ()
{
    const [docs, setdocs] = useState([])
    

    useEffect(() => {
        axios.get('/api/docs/list').then(reponse => {
            setdocs(reponse.data)
        })    
    },[])

    const deleteDoc = (event) => {

        let select = event.target.parentNode.getAttribute("value")

        let chaine = select.split("-")

        let data = {}

        data.chemin = chaine[0]
        data.id = chaine[1]
    
        axios.post('/api/docs/delete', {
            data: data
        }).then(reponse => {
            setdocs(reponse.data)
        })

    }

    return(
        <div className="Docs">
            <div className="docs-titre">
                <h1> Gestion des Documents </h1>
             </div>
            <div className="docs-data">
                <div className="docs-data-gauche">
                    <div className="docs-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="docs-data-gauche-menu">
                        <Link to="/admin" className="docs-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/docsadd" className="docs-data-gauche-menu-lien"> Ajouter des documents </Link> 
                    </div>
                </div>
                <div className="docs-data-droite">
                    <div className="docs-data-droite-titre">
                        <h1> Gestions des Documents </h1>
                    </div>
                    <div className="docs-list">
                       {docs.map(ligne =>
                            <div key={ligne._id} className="elt-ligne">
                                <div>
                                    <p>{ligne.nom} </p> 
                                </div>
                                {ligne.type === "ar" ?
                                    (
                                        <p> Avis de Réunion </p>
                                    )
                                    :
                                    (
                                        <p> Compte Rendu du Conseil </p>
                                    )
                                }
                                <a href={ligne.chemin}> Voir PDF </a>
                                <div className="elt-ligne-actions" value={ligne.chemin+"-"+ligne._id}>
                                    <button onClick={deleteDoc}> Supprimer </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
   
}

export default Docs