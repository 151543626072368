import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Accueil from './Composants/Accueil';
import Commune from './Composants/Commune/Commune';
import Menu from './Composants/Menu/Menu';
import MenuAdmin from './Composants/MenuAdmin/MenuAdmin';
import Footer from './Composants/Footer/Footer'
import Demarches from './Composants/Demarches/Demarches'
import Login from './Composants/Login/Login'
import Logout from './Composants/Logout/Logout'
import Admin from './Composants/Admin/Admin'
import Users from './Composants/Admin/Users/Users'
import UsersAdd from './Composants/Admin/Users/UsersAdd'
import Photos from './Composants/Admin/Photos/Photos'
import PhotosAdd from './Composants/Admin/Photos/PhotosAdd'
import Actus from './Composants/Admin/Actus/Actus'
import ActusAdd from './Composants/Admin/Actus/ActusAdd'
import ActusEdit from './Composants/Admin/Actus/ActusEdit'
import Assoc from './Composants/Admin/Assoc/Assoc'
import AssocAdd from './Composants/Admin/Assoc/AssocAdd'
import AssocEdit from './Composants/Admin/Assoc/AssocEdit'
import Events from './Composants/Admin/Events/Events'
import EventsAdd from './Composants/Admin/Events/EventsAdd'
import EventsEdit from './Composants/Admin/Events/EventsEdit'
import DocsAdd from './Composants/Admin/Docs/DocsAdd'
import Docs from './Composants/Admin/Docs/Docs'
import Horaires from './Composants/Admin/Horaires/Horaires'
import HorairesAdd from './Composants/Admin/Horaires/HorairesAdd'
import HorairesEdit from './Composants/Admin/Horaires/HorairesEdit';
import Documents from './Composants/Documents/Documents'
import Textes from './Composants/Admin/Textes/Textes'
import TextesAdd from './Composants/Admin/Textes/TextesAdd'
import TextesEdit from './Composants/Admin/Textes/TextesEdit'

/*Barre de Cookies */
import Cookies from '../src/Composants/Barre/Cookies/Cookies'

function App() {
  return (
    <Router> 
      <div className="App">
        <Routes>
            <Route path="/" element={<><Menu/><Accueil/><Footer/></>}></Route>
            <Route path="/login" element={<><Menu/><Login/><Footer/></>}></Route>
            <Route path="/logout" element={<><Logout/></>}></Route>
            <Route path="/admin" element={<><MenuAdmin/><Admin/></>}></Route>
            <Route path="/admin/events" element={<><MenuAdmin/><Events/></>}></Route>
            <Route path="/admin/eventsadd" element={<><MenuAdmin/><EventsAdd/></>}></Route>
            <Route path="/admin/eventsedit/:id" element={<><MenuAdmin/><EventsEdit/></>}></Route>
            <Route path="/admin/photos" element={<><MenuAdmin/><Photos/></>}></Route>
            <Route path="/admin/photosadd" element={<><MenuAdmin/><PhotosAdd/></>}></Route>
            <Route path="/admin/users" element={<><MenuAdmin/><Users/></>}></Route>
            <Route path="/admin/horaires" element={<><MenuAdmin/><Horaires/></>}></Route>
            <Route path="/admin/horairesadd" element={<><MenuAdmin/><HorairesAdd/></>}></Route>
            <Route path="/admin/horairesedit/:id" element={<><MenuAdmin/><HorairesEdit/></>}></Route>
            <Route path="/admin/usersadd" element={<><MenuAdmin/><UsersAdd/></>}></Route>
            <Route path="/admin/actus" element={<><MenuAdmin/><Actus/></>}></Route>
            <Route path="/admin/actusadd" element={<><MenuAdmin/><ActusAdd/></>}></Route>
            <Route path="/admin/actusedit/:id" element={<><MenuAdmin/><ActusEdit/></>}></Route>
            <Route path="/admin/assoc" element={<><MenuAdmin/><Assoc/></>}></Route>
            <Route path="/admin/assocadd" element={<><MenuAdmin/><AssocAdd/></>}></Route>
            <Route path="/admin/assocedit/:id" element={<><MenuAdmin/><AssocEdit/></>}></Route>
            <Route path="/admin/docs" element={<><MenuAdmin/><Docs/></>}></Route>
            <Route path="/admin/docsadd" element={<><MenuAdmin/><DocsAdd/></>}></Route>
            <Route path="/admin/textes" element={<><MenuAdmin/><Textes/></>}></Route>
            <Route path="/admin/textesadd" element={<><MenuAdmin/><TextesAdd/></>}></Route>
            <Route path="/admin/textesedit/:id" element={<><MenuAdmin/><TextesEdit/></>}></Route>
            <Route path="/commune" element={<><Menu/><Commune/><Footer/></>}></Route>
            <Route path="/demarches" element={<><Menu/><Demarches/><Footer/></>}></Route>
            <Route path="/docs" element={<><Menu/><Documents/><Footer/></>}></Route>
          </Routes> 
          <Cookies></Cookies>
      </div>
    </Router>
  );
}

export default App;
