import './Documents.css'
import axios from 'axios'
import {useEffect, useState} from 'react'

function Documents ()
{
    const [avisReunion, setAvisReunion] = useState([])
    const [crConseil, setcrConseil] = useState([])

    useEffect(() => {
        axios.get('/api/docs/list/ar').then(reponse => {
            setAvisReunion(reponse.data)
        })

        axios.get('/api/docs/list/cr').then(reponse => {
            setcrConseil(reponse.data)
        })  
    },[])

    return (
        <div className="Documents">
            <div className="documents-accueil">
                <h1>Les documents </h1> 
            </div>
            <div className="documents-data">
                <div className="documents-data-gauche">
                    <h1> Avis de Réunion </h1>
                    {avisReunion.map(avis => 
                        <div key={avis._id} value={avis._id} className="avis-element">
                            <a href={avis.chemin}>{avis.nom}</a>
                        </div>    
                    )}
                </div>
                <div className="documents-data-droite">
                    <h1> Compte Rendu du Conseil </h1> 
                    {crConseil.map(cr => 
                        <div key={cr._id} value={cr._id} className="avis-element">
                            <a href={cr.chemin}>{cr.nom}</a>
                        </div>    
                    )}
                </div>
            </div>
        </div>
    )
}

export default Documents