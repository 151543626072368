import './Users.css'
import { Link } from 'react-router-dom'
import {useState,useEffect} from 'react'
import axios from 'axios'
import UserLigne from './UserLigne'

function Users () {

    const [users, setUsers] = useState([])

    useEffect(() => {
        getUsers()
    },[])

    function getUsers() {
        axios.get('/api/users/list').then(reponse => {
            setUsers(reponse.data)
        })
    }
    
    return (
        <div id="Users">
            <div className="users-titre">
                <h1> Utilisateurs </h1> 
            </div>
            <div className="users-data">
                <div className="users-data-gauche">
                    <div className="users-data-gauche-entete">
                        <h1 >Menu </h1> 
                    </div>
                    <div className="users-data-gauche-menu">
                        <Link to="/admin/usersadd" className="users-data-gauche-menu-lien"> Création d'un Utilisateur </Link>
                        <Link to="/admin" className="users-data-gauche-menu-lien"> Revenir Page d'Accueil </Link> 
                    </div>
                </div>
                <div className="users-data-droite">
                    <div className="users-data-droite-entete">
                        <p> Login </p>
                        <p> Password </p>
                        <p> Nom </p>
                        <p> Prénom </p>
                        <p> Email </p>
                        <p> Role </p>
                        <p> Action </p> 
                    </div>
                    <div className="users-data-droite-list">
                    {users && users.map(user =>
                        <UserLigne key={user._id} data={user}></UserLigne>
                    )}
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default Users