import './MenuAdmin.css'
import {Link} from 'react-router-dom'
import logo from '../../Image/logo.png'

function MenuAdmin () {
    
    return (
        <div id="MenuAdmin">
            <div className="menu-lien">
                <img alt="menu-lien-logo" className="menu-lien-logo" src={logo}></img>
            </div>
            <div className="menu-lien">
                <Link to="/admin" className="menu-admin-lien"> Accueil </Link>
            </div>
            <div className="menu-lien">
                <Link to="/admin/photos" className="menu-admin-lien"> Photos </Link>
            </div>
            <div className="menu-lien">
                <Link to="/admin/events" className="menu-admin-lien"> Evenements </Link>
            </div>
            <div className="menu-lien">
                <Link to="/admin/actus" className="menu-admin-lien"> Actualités </Link>
            </div>
            <div className="menu-lien">
                <Link to="/admin/users" className="menu-admin-lien"> Utilisateurs </Link>
            </div>
            <div className="menu-lien">
                <Link to="/logout" className="menu-admin-lien"> Déconnexion </Link>
            </div>
        </div>  
    )
}

export default MenuAdmin