import './Diaporama.css'
import prec from '../../Image/fleches.png'
import suiv from '../../Image/fleches.png'
import React, {useState, useCallback, useEffect,useRef} from 'react'

function Diaporama (props) {
  
    const index = useRef(1)
    const interval = useRef(null)
    const diaporama = useRef(null)
    const [tabdiapos] = useState([])
    const [nbdiapos, setnbDiapos] = useState(0)
    const [taille, setTaille] = useState()
    const [diapos, setDiapos] = useState([])
    const [datadiapos, setdatadiapos] = useState([])
    const [slider, setSlider] = useState()
    const [tabclonage] = useState([])
    const [isauto, setisauto] = useState(true)
        
    const sliderRef = useCallback(slider => {
        setSlider(slider)
    },[])
    
    const diaposRef = useCallback(diapo => {
            
        if(diapo)
        {
            tabdiapos.push(diapo)
            setDiapos(tabdiapos)
            setnbDiapos(tabdiapos.length + 2)
            diapo.style.width = window.innerWidth + "px"
            diapo.firstElementChild.style.width = window.innerWidth + "px"
            setTaille(diapo.clientWidth)
            
            window.addEventListener("resize", () => {
        
                if(diaporama.current == null)
                {
                   console.log("SALUT")
                }
                else
                {
                    diaporama.current.style.width = window.innerWidth + "px"
                    diaporama.current.style.height = "auto"
                    diapo.style.width = window.innerWidth + "px"
                    diapo.firstElementChild.style.width = window.innerWidth + "px"
                    setTaille(diapo.clientWidth)
                }

                
            }) 
        }
    },[tabdiapos])

    useEffect(() => {
        
        setdatadiapos(props.data)
        if(diapos[0] !== undefined)
        {            
            //Decalage du Premier Element
            slider.style.transform = 'translateX('+(- taille * index.current) + 'px)'; 
            slider.style.transition = 'none';
            slider.style.width = window.innerWidth + "px"
            //Clonage
            if(!tabclonage.includes("clonage"))
            {
                let firstclone = slider.firstElementChild.cloneNode(true)
                let lastclone = diapos[diapos.length - 1].cloneNode(true)
               
                window.addEventListener("resize", () => {
                    diaporama.current.style.width = window.innerWidth + "px"
                    diaporama.current.style.maxHeight = "600px"
                    slider.style.width = window.innerWidth + "px"
                    
                    firstclone.style.width = window.innerWidth + "px"
                    lastclone.style.width = window.innerWidth + "px"
                    firstclone.firstElementChild.style.width = window.innerWidth + "px"
                    lastclone.firstElementChild.style.width = window.innerWidth + "px"
                })
    
                //Clonage Dernier Element en Premier
                slider.insertBefore(lastclone, slider.firstElementChild)
    
                //Clonage Premier Element en dernier 
                slider.appendChild(firstclone)
                tabclonage.push("clonage")
            }            
        }

    },[diapos,slider,taille,index,props.data,tabclonage])

    const diapoSuiv = () => {

        setisauto(false)

        if(index.current > (Number(nbdiapos) - 2))
        {
            index.current = 1
        }
        else
        {
            index.current = Number(index.current) + 1
            slider.style.transition = "transform 0.5s ease-in-out"
            slider.style.transform = 'translateX('+(-taille * index.current) + 'px)';
        }
              
    }

    const diapoPrec = () => {
        
        setisauto(false)
        
        if(index.current < 1)
        {
            index.current = Number(nbdiapos) - 2
            slider.style.transition = "transform 0.5s ease-in-out"
            slider.style.transform = 'translateX('+(-taille * Number(nbdiapos - 2)) + 'px)';
        }
        else
        {
            index.current = Number(index.current) - 1
            slider.style.transition = "transform 0.5s ease-in-out"
            slider.style.transform = 'translateX('+(-taille * index.current) + 'px)';
        }
    }

    const transition = () => {
    
        if(Number(index.current) < 1)
        {
            index.current = Number(nbdiapos - 2)
            slider.style.transition = "none"
            slider.style.transform = 'translateX('+(-taille * index.current )+ 'px)';  
        }
        else if(index.current > nbdiapos - 2)
        {
            index.current = 1
            slider.style.transition = "none"
            slider.style.transform = 'translateX('+(-taille * index.current )+ 'px)';  
        }
    }

    useEffect(() => {
        interval.current = setInterval(() => {

            if(slider)
            {
                
                if(index.current > (Number(nbdiapos) - 2))
                {
                    slider.style.transition = "transform 0.5s ease-in-out"
                    slider.style.transform = 'translateX('+(-taille * nbdiapos) + 'px)';
                }
                else
                {
                    index.current = Number(index.current) + 1
                    slider.style.transition = "transform 0.5s ease-in-out"
                    slider.style.transform = 'translateX('+(-taille * index.current) + 'px)';
                }
                
            }
           
        }, 3000)

    },[nbdiapos,slider,taille])

    window.addEventListener("resize", () => {
        setisauto(false)
    })

    if(isauto === false)
    {
        clearInterval(interval.current)
    }
    
    return (
        <div ref={diaporama} id="Diaporama">
            <div ref={sliderRef} onTransitionEnd={transition} className={props.classslider}>
                {datadiapos.map((diapounique,index) =>
                    <div ref={diaposRef} key={index} index={index} className={props.classdiapos}>
                        <img alt="diapos-img" src={diapounique.chemin}></img>
                    </div>
                )}
            </div>
            <img src={prec} onClick={diapoPrec} alt="fleche-gauche" className="diaporama-fleches-prec"></img>
            <img src={suiv} onClick={diapoSuiv} alt="fleche-droite" className="diaporama-fleches-suiv"></img>
        </div>
    )    
}

export default Diaporama