import './Popup.css'
import imgquit from '../../Image/quit.png'
import parse from 'html-react-parser'

function Popup (props) 
{ 
    
    const quitPopup = () => {
        props.isclose(false)
    
    }

    /*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2630.317547661199!2d5.1788244!3d48.7567319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47eb7a86f349fff3%3A0x7239ca9e56f5a0e2!2s1%20Rue%20Alexandre%20Violle%2C%2055000%20Savonni%C3%A8res-devant-Bar!5e0!3m2!1sfr!2sfr!4v1667057636390!5m2!1sfr!2sfr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>*/

    return (
        <div id="Popup">
            <div className="popup-data">
                <div className="popup-data-titre">
                    <h1> {props.data.titre} </h1>
                </div>
                <div className="popup-data-form">
                    <img alt="popup-data-img-quit" onClick={quitPopup} className="popup-data-img-quit" src={imgquit}></img>
                    <p>{parse(props.data.contenu)}</p>
                </div>
            </div>
        </div>
    )
}

export default Popup