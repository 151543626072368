import './Assoc.css'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {useState, useEffect} from 'react'

function Actus ()
{
    const [tabassoc, setTabAssoc] = useState([])

    useEffect(() => {
        axios.get('/api/assoc/list').then(reponse => {
            setTabAssoc(reponse.data)
        })

    },[])

    const goEdit = (event) => {

        let id = event.target.parentNode.getAttribute('value')

        document.location.href = "/admin/assocedit/"+id
    }

    const deleteAssoc = async (event) => {

        let id = event.target.parentNode.getAttribute('value')

        let reponse = await axios.post('/api/assoc/delete', {
            id: id
        })

        if(reponse.data === "ok")
        {
            alert("L'actualité a été supprimée ")
            document.location.href = "/admin/assoc"
        }
    }
  
    return (
        <div className="Actus">
            <div className="assoc-titre">
                <h1>Gestion des Associations </h1>
             </div>
            <div className="assoc-data">
                <div className="assoc-data-gauche">
                    <div className="assoc-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="assoc-data-gauche-menu">
                        <Link to="/admin" className="assoc-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/assocadd" className="assoc-data-gauche-menu-lien"> Ajouter des assoc </Link> 
                    </div>
                </div>
                <div className="assoc-data-droite">
                    <div className="assoc-data-droite-titre">
                        <h1> Liste des Associations</h1>     
                    </div> 
                    {tabassoc && 
                        <div classname="assoc-second">
                            <div className="assoc-second-data">
                            {tabassoc.map(assoc => 
                                <div className="assoc-second-ligne">
                                    <div className="assoc-second-ligne-titre">
                                        <p> {assoc.titre} </p>
                                    </div>
                                    <div className="assoc-second-ligne-image">
                                        <img alt="assoc-second-img" src={assoc.cheminimg}></img> 
                                    </div>
                                    <div value={assoc._id} className="assoc-second-ligne-actions">
                                        <button onClick={deleteAssoc}> Supprimer </button>
                                        <button onClick={goEdit}> Editer </button>
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Actus