import './PhotosAdd.css'
import axios from 'axios'
import {useState} from 'react'
import { Link } from 'react-router-dom'

function PhotosAdd ()
{
    const [Images, setImages] = useState([])
    const [typeDiapo, setTypeDiapo] = useState()
    const [progression, setProgression] = useState()
    
    const handleDiapos = (event) =>
    {
        setImages(event.target.files)
    }

    const handleTypeDiaporama = (event) => {
        setTypeDiapo(event.target.value)
    }

    const sendImgDiaporama = async (event) =>
    {  
        let data = new FormData()
        event.preventDefault()

        for(let i= 0 ; i < Images.length ; i++)
        {
            data.append('images', Images[i])                 
        }
        
        if(typeDiapo === "accueil")
        {
            axios.post('/api/photos/addaccueil', data, {
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: async (progressEvent) => {
                    setProgression(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(reponse => {
                if(reponse.data === "ok")
                {
                    alert("Les photos ont été ajoutées")
                    document.location.href = "/admin/photos"
                }
            })
        }
        else if(typeDiapo === "commune")
        {
            axios.post('/api/photos/addcommune', data, {
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: async (progressEvent) => {
                    setProgression(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(reponse => {
                if(reponse.data === "ok")
                {
                    alert("Les photos ont été ajoutées")
                    document.location.href = "/admin/photos"
                }
            })
        }
        else if(typeDiapo === "demarches")
        {
            axios.post('/api/photos/adddemarches', data, {
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: async (progressEvent) => {
                    setProgression(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(reponse => {
                if(reponse.data === "ok")
                {
                    alert("Les photos ont été ajoutées")
                    document.location.href = "/admin/photos"
                }
            })
        }
        else if(typeDiapo === "salles")
        {
            axios.post('/api/photos/addsalles', data, {
                headers:{
                    'Content-Type':'multipart/form-data'
                },
                onUploadProgress: async (progressEvent) => {
                    setProgression(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                }
            }).then(reponse => {
                if(reponse.data === "ok")
                {
                    alert("Les photos ont été ajoutées")
                    document.location.href = "/admin/photos"
                }
            })
        }
       
    }

    return (
        <div className="PhotosAdd">
            <div className="photosadd-titre">
                    <h1> Ajouter des Photos </h1>
             </div>
            <div className="photosadd-data">
                <div className="photosadd-data-gauche">
                    <div className="photosadd-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="photos-data-gauche-menu">
                        <Link to="/admin" className="photos-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/photosadd" className="photos-data-gauche-menu-lien"> Ajouter des Photos </Link> 
                    </div>
                </div>
                <div className="photosadd-data-droite">
                    <div className="photosadd-form">
                        <select onChange={handleTypeDiaporama}>
                            <option> Sélectionner un Type De Diaporama </option>
                            <option value="accueil"> Accueil </option>
                            <option value="commune"> Commune </option>
                            <option value="demarches"> Démarches </option> 
                            <option value="salles"> Salles </option> 
                        </select>
                        {typeDiapo && 
                            <div>
                                <input type="file" placeholder="Selectionner une Image" onChange={handleDiapos} multiple></input>
                                <button onClick={sendImgDiaporama}>Envoyer</button>  
                            </div> 
                        }                        
                    </div>
                    {progression &&
                        <p className="photosadd-loading"> Téléchargement en cours {progression} % </p>
                    }
                </div>
            </div>
        </div>
    )
    
}

export default PhotosAdd