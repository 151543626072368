import './Commune.css'
import axios from 'axios'
import panneau from '../../Image/panneau.jpg'
import canap1 from '../../Image/canap1.jpg'
import canap2 from '../../Image/canap2.gif'
import canap3 from '../../Image/canap3.gif'
import maire from '../../Image/maire.jpg'
import parse from 'html-react-parser'
import {useState, useEffect} from 'react'
import Diaporama from '../Diaporama/Diaporama'
import Popup from '../Popup/Popup'

function Commune ()
{
    const [tabdiapos, setTabDiapos] = useState([])
    const [tabassoc, setTabAssoc] = useState([])
    
    //Popup
    const [dataPopup, setDataPopup] = useState([])
    const [dataPopup2, setDataPopup2] = useState([])
    const [dataPopup3, setDataPopup3] = useState([])
    const [isPopupWar1, setisPopupWar1] = useState(false)
    const [isPopupWar2, setisPopupWar2] = useState(false)
    const [isPopupWar3, setisPopupWar3] = useState(false)
    const [assocSelect, setAssocSelect] = useState()

    useEffect(() => {
        axios.post('/api/textes/listbycode', {
            code: "TXT2205-201"
        }).then(reponse => {
            setDataPopup(reponse.data)
        })

        axios.post('/api/textes/listbycode', {
            code : "TXT2205-202"
        }).then(reponse => {
            setDataPopup2(reponse.data)
        })

        axios.post('/api/textes/listbycode', {
            code : "TXT2205-203"
        }).then(reponse => {
            setDataPopup3(reponse.data)
        })
    },[])

    const showPopup = () => {
        setisPopupWar1(true)
    }

    const showPopup2 = () => {
        setisPopupWar2(true)
    }

    const showPopup3 = () => {
        setisPopupWar3(true)
    }

    const selectAssoc = (event) => {
        
        let id = event.target.getAttribute('value')

        setAssocSelect(id)
    }

    
    useEffect(() => {
        axios.get('/api/photos/listcommune').then(reponse => {
            setTabDiapos(reponse.data)
        })
    },[])

    useEffect(() => {
        axios.get('/api/assoc/list').then(reponse => {
            setTabAssoc(reponse.data)
            setAssocSelect(reponse.data[0]._id)
        })
    },[])

    return (

        <div className="Commune">
            <Diaporama data={tabdiapos} classdiapos="diapos" classslider="slider"></Diaporama>
            <div className='commune-commu'>
                <h1>La commune</h1>
            </div>
            {isPopupWar1 &&
                <Popup isclose={isPopupWar1 => setisPopupWar1(isPopupWar1)} data={dataPopup}></Popup> 
            }
            {isPopupWar2 &&
                <Popup isclose={isPopupWar2 => setisPopupWar2(isPopupWar2)} data={dataPopup2}></Popup> 
            }
            {isPopupWar3 && 
                <Popup isclose={isPopupWar3 => setisPopupWar3(isPopupWar3)} data={dataPopup3}></Popup>
            }
            <div className='commune-geo'>
                <div className='commune-geoG'>
                    <p>Savonnières-devant-Bar est une commune française située dans le département de la Meuse en région Lorraine.</p>
                    <h1>Géographie</h1>
                    <p>Ancrée au sud-ouest de la région Lorraine, la commune est située dans le nord-est de la France à 80 km de Nancy, 120 km de Metz et à 225 km de Paris. Elle comptait 482 habitants au dernier recensement de 2011. Elle dépend de la communauté d’agglomération Grand Sud qui regroupe environ 30 000 habitants le long de la vallée de l’Ornain (Revigny-sur-Ornain – Bar-le-Duc – Ligny-en-Barrois).</p>
                    <h1>Toponymie</h1>
                    <p>L’origine du nom Savonnières provient de la saponaire. La saponaire officinale (Saponaria officinalis) est une plante herbacée vivace de la famille des Caryophyllaceae. On l’appelle aussi savonnaire, savonnière, saponière, herbe à savon, herbe à foulon, savon des fossés ou savon de fosse. Le village est mentionné pour la première fois en 1460, sous le nom de SAPONARIAS. Cette étymologie tendrait à prouver l’existence dès l’époque gallo-romaine, d’une fabrique de savon sur le territoire de la commune.</p>
                </div>

                <div className='commune-geoD'>
                    <img src={panneau} alt='panneau'></img>
                </div>
            </div>

            <div className='commune-histoire'>
                <h1>L'histoire de Savonnières-devant-Bar</h1>
                <p>L’origine de Savonnières-devant-Bar remonte probablement à celle de sa plus grande voisine, Bar-le-Duc.
                    La proximité de la voie romaine qui passait dans la vallée de l’Ornain est à relier à l’existence d’une activité sur l’endroit.</p>
                <p>Le village appartient jusqu’en 1460 à l’abbaye de Saint-Mihiel qui le cède ensuite à René, roi de Sicile et Duc de Bar.</p>
                <p>L’église Saint-Calixte serait la réplique exacte — à l’échelle 1/2 — de l’ancienne abbatiale qui avait été érigée au cœur du château des ducs de Bar.</p>
                <p>L’influence italienne a été très forte et elle continue de perdurer dans les nombreux ouvrages et monuments qui subsistent dans la ville haute de Bar-le-Duc.</p>
                <p>Louis XVI met un terme à l’influence financière et politique des ducs de Bar. Les remparts ainsi que le château sont abattus.</p>
                <p>Seule la tour de l’horloge est épargnée. Elle constitue aujourd’hui le dernier vestige de l’ancienne enceinte féodale.</p>
                <p>C’est toutefois lors de la Renaissance qu’émergent certains repères historiques, au moment où quelques familles nobles s’installent sur l’actuel territoire de la commune. La famille DE LA MORE ou DE LAMORRE est probablement celle qui laissera son empreinte la plus profonde sur Savonnières-devant-Bar et ce, durant plusieurs siècles, jusqu’à la Révolution française. En 1746, l’un des membres de cette famille possède la charge de conseiller auditeur et de secrétaire de la chambre du conseil et des comptes de Bar le Duc.</p>
                <p>C’est la révolution industrielle du XIXe siècle qui va directement bénéficier à l’essor de la commune avec notamment, la proximité d’avec les industries proches de la vallée de l’Ornain : fonderies, textile, brasseries, etc.</p>
                <p>Par deux fois, en 1870 et en 1937, une partie du territoire de la commune a été concédée à la ville de Bar le Duc pour favoriser son développement industriel.</p>
            </div>

            <div className='commune-guerre'>
                <div className='commune-guerre-cadre'>
                    <div className="commune-guerre-cadre-gauche">
                        <h1>La Première Guerre Mondiale</h1>
                        <button onClick={showPopup}>En savoir plus</button>     
                    </div>
                    <div className="commune-guerre-cadre-droite">
                        <img src={canap1} alt='canap1'></img>
                    </div>
                </div>
                <div className='commune-guerre-cadre'>
                    <div className="commune-guerre-cadre-gauche">
                        <img src={canap2} alt='canap2'></img>
                    </div>
                    <div className="commune-guerre-cadre-droite">
                        <h1>La Seconde Guerre Mondiale</h1>
                        <button onClick={showPopup2}>En savoir plus</button>
                    </div>
                </div>
                <div className='commune-guerre-cadre'>
                    <div className="commune-guerre-cadre-gauche">
                        <h1>La Periode Contemporaine</h1>
                        <button onClick={showPopup3}>En savoir plus</button> 
                    </div>
                    <div className="commune-guerre-cadre-droite">
                        <img src={canap3} alt='canap3'></img>
                    </div>
                </div>
            </div>
            <div className="commune-maire">
                <h1>Le mot du maire</h1>
                <p>"Je mesure parfaitement le poids de mes responsabilités et je tiens à remercier celles et ceux qui, autour de moi, viennent de m’accorder leur confiance."</p>
                <img src={maire} alt='maire'></img>
                <h2>GERALD MICHEL</h2>
            </div>
            <div className="commune-partie7">
                <h1>LES ASSOCIATIONS</h1>
                <div className="commune-club">
                    {tabassoc.map(assoc => 
                            <div onClick={selectAssoc} value={assoc._id} key={assoc._id}>
                                <h4 onClick={selectAssoc} value={assoc._id}> {assoc.titre} </h4>
                            </div>
                        )
                    }
                </div>
                <div className="commune-para">
                   {tabassoc.map(assoc => 
                            assocSelect === assoc._id ?
                            (
                                <div key={assoc._id} className="assoc">
                                    {assoc.text &&
                                        <p> {parse(assoc.text)}</p>
                                    }
                                    <img src={assoc.cheminimg} alt="gym"></img>
                                </div>
                            ):null
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Commune