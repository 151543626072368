import './MenuMobile.css'
import logo from '../../Image/logo.png' 
import menumobile from '../../Image/menumobile.png'
import menumobilequit from '../../Image/quit.png'

function MenuMobile (props)
{
    
    const displaySousMenu = () => {
        props.issousmenumobile(true)
    }

    const quitSousMenu = () => {
        props.issousmenumobile(false)
    }

    return (
        <div className="MenuMobile">
            <div className="menumobile-princ">
                <div className="menumobile-princ-gauche">
                    <img src={logo} alt="menumobile-logo"></img>
                </div>
                {props.data === false ?
                    (
                        <div className="menumobile-princ-droite">
                            <img onClick={displaySousMenu} src={menumobile} alt="menumobile-menu"></img>
                        </div>
                    )
                    :
                    (
                        <div className="menumobile-princ-droite">
                            <img onClick={quitSousMenu} src={menumobilequit} alt="menumobile-menu"></img>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default MenuMobile