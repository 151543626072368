import './Textes.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {useState, useEffect} from 'react'

function Textes () 
{
    const [textes, setTextes] = useState([])

    useEffect(() => {
        axios.get('/api/textes/list').then(reponse => {
            setTextes(reponse.data)
        })
    },[])

    const deleteTexte = (event) => {
        let id = event.target.parentNode.getAttribute("value")

        axios.post('/api/textes/deletebyid',{
            id: id
        }).then(reponse => {
            setTextes(reponse.data)
        })
    }

    const goEdit = (event) => {

        let id = event.target.parentNode.getAttribute("value")

        document.location.href = "/admin/textesedit/"+id
    }

    return(
        <div className="textes">
            <div className="textes-titre">
                <h1> Gestion des Textes </h1>
             </div>
            <div className="textes-data">
                <div className="textes-data-gauche">
                    <div className="textes-data-gauche-entete">
                        <h1> Menu </h1> 
                    </div>
                    <div className="textes-data-gauche-menu">
                        <Link to="/admin" className="textes-data-gauche-menu-lien"> Revenir Page d'Accueil </Link>
                        <Link to="/admin/textesadd" className="textes-data-gauche-menu-lien"> Ajouter des textes </Link> 
                    </div>
                </div>
                <div className="textes-data-droite">
                    <div className="textes-data-droite-entete">
                        <p> Code </p>
                        <p> Titre </p>
                        <p> Date de Création </p>
                        <p> Action </p>
                    </div>
                    <div className="textes-data-droite-list">
                        {textes.map(lignetxt => 
                            <div key={lignetxt._id} className="texte-ligne">
                                <p>{lignetxt.codefinal} </p>
                                <p> {lignetxt.titre} </p>
                                <p> {new Date(lignetxt.created_at).toLocaleDateString()} </p>
                                <div value={lignetxt._id}>
                                    <button onClick={deleteTexte}> Supprimer </button>
                                    <button onClick={goEdit}> Editer </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Textes